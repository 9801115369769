import { isObject, isString } from 'lodash-es'
import { Text } from '@yms/hexagon'

export function getErrorMessage(error: unknown) {
  if (isObject(error)) {
    const trace = []
    if ('message' in error && isString(error.message)) {
      trace.push(<div key="message">Error message: {error.message}</div>)
    }

    if ('stack' in error && isString(error.stack)) {
      trace.push(
        <ul key="stack-trace">
          {error.stack.split(' at').map((stack, i) => (
            <li style={{ listStyleType: 'circle', textAlign: 'left' }} key={stack}>
              <Text size="xs" color="weak">
                {i === 0 ? '' : 'at '} {stack}
              </Text>
            </li>
          ))}
        </ul>
      )
    }

    return <div>{trace}</div>
  } else if (isString(error)) {
    return error
  } else {
    return 'Unknown error'
  }
}
