import { type DependencyList, useEffect } from 'react'

import { type Callback, callbackSet } from './setupNotifications'

export const notificationHandler = (handler: Callback) => {
  callbackSet.add(handler)

  return () => callbackSet.delete(handler)
}

export const useNotificationHandler = (handler: Callback, dependencyList: DependencyList) => {
  useEffect(() => {
    const unregister = notificationHandler(handler)

    return () => {
      unregister()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList)
}
