import { isString } from 'lodash-es'
import { type QueryClient } from '@tanstack/react-query'
import { type YardTaskProjection } from '@yms/api/odata-client/ItgPltYmsApiModel'
import { blockDetail } from '@yms/common/components/YardLocation/LocationSelector/SlotSelection/useBlockDetailsQuery'
import { slots } from '@yms/common/components/YardLocation/LocationSelector/SlotSelection/useSlotsQuery'
import { blocks } from '@yms/common/components/YardLocation/LocationSelector/BlockSelection/useBlocksQuery'
import { yardZone } from '@yms/common/components/YardLocation/LocationSelector/YardZoneSelection/useYardZoneQuery'

import { currentYardZoneIds } from '@mobile/auth/current-session/storage'
import { client } from '@mobile/api/configuration'

/**
 * Not sure whether to keep this together or split it further between YZ/Block/Slot selection prefetchers.
 * Let's keep it like that for now
 * @param queryClient
 * @param row
 * @returns
 */
export const prefetchLocation = async (queryClient: QueryClient, row: YardTaskProjection) => {
  const location = row.operatorsPlannedToLocationDetails ?? row.plannedToLocationDetails
  if (!isString(row.siteCode)) return

  const hasYardZone = isString(location?.yardZone)
  const hasBlock = isString(location?.block)

  if (hasYardZone && hasBlock) {
    const locationPayload = {
      client,
      site: row.siteCode,
      slot: location.slot as string, // TSbug
      block: location.block as string, // TSbug
      yardZone: location.yardZone as string // TSbug
    }

    queryClient.ensureQueryData({
      queryKey: blockDetail.queryKey(locationPayload),
      queryFn: ({ signal }) => blockDetail.queryFn({ signal, ...locationPayload })
    })

    queryClient.ensureQueryData({
      queryKey: slots.queryKey(locationPayload),
      queryFn: ({ signal }) => slots.queryFn({ signal, ...locationPayload })
    })
  } else if (hasYardZone) {
    const locationPayload = {
      site: row.siteCode,
      slot: location.slot as string, // TSbug
      yardZone: location.yardZone as string // TSbug
    }

    queryClient.ensureQueryData({
      queryKey: blocks.queryKey(locationPayload),
      queryFn: ({ signal }) => blocks.queryFn({ signal, ...locationPayload })
    })
  } else {
    const yardZoneIds = await queryClient.ensureQueryData(currentYardZoneIds)
    const locationPayload = { client, site: row.siteCode, yardZoneIds }

    queryClient.ensureQueryData({
      queryKey: yardZone.queryKey(locationPayload),
      queryFn: ({ signal }) => yardZone.queryFn({ signal, ...locationPayload })
    })
  }
}
