/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GetOperationalSlotsODataParams,
  HttpValidationProblemDetails,
  ODataOperationalSlotDtoArrayODataCollectionResponse
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class OperationalSlots<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * No description
   *
   * @tags OperationalSlots
   * @name GetOperationalSlotsOData
   * @request GET:/api/v1/operational-slots
   * @secure
   */
  getOperationalSlotsOData = (query: GetOperationalSlotsODataParams, params: RequestParams = {}) =>
    this.http.request<
      ODataOperationalSlotDtoArrayODataCollectionResponse,
      HttpValidationProblemDetails
    >({
      path: `/api/v1/operational-slots`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
}
