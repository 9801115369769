import { type MutableRefObject, useEffect } from 'react'

export const useListener = <ElementType extends Element, TEventListener = EventListener>(
  ref: MutableRefObject<ElementType | undefined>,
  event: string,
  listener?: TEventListener
) => {
  useEffect(() => {
    const currentRef = ref.current
    if (currentRef && listener) {
      currentRef.addEventListener(event, listener as EventListener)

      return () => {
        currentRef.removeEventListener(event, listener as EventListener)
      }
    }
  }, [event, listener, ref])
}
