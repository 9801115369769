import { delMany } from 'idb-keyval'

import { client } from '../api/configuration'
import { userManager } from './userManager'

userManager.events.addUserLoaded(async user => {
  console.info('[auth] User loaded', user)
  client.setSecurityData(user.access_token)
})

userManager.events.addAccessTokenExpiring(() => {
  console.info('[auth] Access token expiring')
})

userManager.events.addUserUnloaded(() => {
  client.setSecurityData(null)
})

userManager.events.addSilentRenewError(error => {
  console.info('[auth] Auth error', error)
})

userManager.events.addAccessTokenExpired(async () => {
  await delMany(['handlerId', 'yardZoneIds'])
  await userManager.removeUser()
  window.location.href = '/auth'
})
