type OdataRequest = {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  top?: number
  skip?: number
  count?: boolean
  search?: string
}

export const odataStringToRecord = (odataString: string) => {
  const params = new URLSearchParams(odataString.slice(1))
  const result: OdataRequest = {}

  if (params.has('$top')) {
    result.top = +(params.get('$top') as string)
  }
  if (params.has('$skip')) {
    result.skip = +(params.get('$skip') as string)
  }

  if (params.has('$select')) {
    result.select = params.get('$select') as string
  }

  if (params.has('$search')) {
    result.search = params.get('$search') as string
  }

  if (params.has('$filter')) {
    result.filter = params.get('$filter') as string
  }

  if (params.has('$count')) {
    result.count = params.get('$count') === 'true'
  }

  if (params.has('$orderby')) {
    result.orderby = params.get('$orderby') as string
  }

  if (params.has('$expand')) {
    result.expand = params.get('$expand') as string
  }

  return result
}
