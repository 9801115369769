import { useEffect, useRef, useState } from 'react'
import { type PrimitiveType } from 'react-intl'
import { v4 } from 'uuid'
import clsx from 'clsx'
import { serializeForHexagon } from '@yms/hexagon/utils/serializeForHexagon'
import { useTranslate } from '@yms/common/hooks/useTranslate'
import { useIsOffline } from '@yms/common/components/Offline/useIsOffline'

import styles from './Snackbar.module.css'
import './snackbar.css'

type SnackbarState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: any
  visible: boolean
  type: 'success' | 'info' | 'warning' | 'error'
  message: string
  textProps: Record<string, PrimitiveType>
  id: string
}

export function SnackbarHandler() {
  const snackbarRef = useRef<HTMLDivElement>(null)
  const translate = useTranslate()
  const [queue, setQueue] = useState<SnackbarState[]>([])
  const isOffline = useIsOffline()

  useEffect(() => {
    const current = snackbarRef.current

    const onDismiss = () => {
      setQueue([])
    }

    if (snackbarRef.current) {
      snackbarRef.current.addEventListener('hx-dismiss', onDismiss)
    }

    return () => {
      if (current) {
        current.removeEventListener('hx-dismiss', onDismiss)
      }
    }
  }, [])

  useEffect(() => {
    function handleOpen(event: Event) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((event as CustomEvent<any>).detail) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { type, message, textProps, action } = (event as CustomEvent<any>).detail

        setQueue([
          {
            id: v4(),
            type,
            message,
            textProps,
            action,
            visible: true
          }
        ])
      }
    }

    document.addEventListener('snackbar:open', handleOpen)

    return () => document.removeEventListener('snackbar:open', handleOpen)
  }, [])

  return (
    <div ref={snackbarRef} className={clsx(styles.wrapper, isOffline && styles.offline)}>
      {queue.map(snackbar => (
        <hx-snackbar
          key={snackbar.id}
          id={snackbar.id}
          duration={6000}
          placement="top-end"
          persistent={serializeForHexagon(snackbar.type === 'error')}
          open={serializeForHexagon(snackbar.visible)}
          variant={snackbar.type}
        >
          {translate(snackbar.message, snackbar.textProps)}
          {snackbar.action}
        </hx-snackbar>
      ))}
    </div>
  )
}
