import { type ReactNode } from 'react'
import type HxBadge from '@katoennatie/hexagon/badge'

import { serializeForHexagon } from './utils/serializeForHexagon'

export type BadgeProps = {
  readonly children?: ReactNode
  readonly value?: HxBadge['value']
  readonly limit?: HxBadge['limit']
  readonly color?: HxBadge['color']
  readonly disabled?: HxBadge['disabled']
  readonly placement?: HxBadge['placement']
  readonly anchorShape?: HxBadge['anchorShape']
  readonly slot?: HxBadge['slot']
}

export function Badge({
  children,
  value,
  limit,
  color,
  disabled,
  placement,
  anchorShape,
  slot
}: BadgeProps) {
  return (
    <hx-badge
      value={value}
      limit={limit}
      color={serializeForHexagon(color)}
      disabled={serializeForHexagon(disabled)}
      placement={serializeForHexagon(placement)}
      anchor-shape={serializeForHexagon(anchorShape)}
      slot={serializeForHexagon(slot)}
    >
      {children}
    </hx-badge>
  )
}
