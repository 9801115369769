import { Text, Button, Heading } from '@yms/hexagon'
import { useTranslate } from '@yms/common/hooks/useTranslate'

import { PageRoot } from '../PageRoot'
import styles from './common.module.css'

type NotFoundErrorPageProps = {
  readonly onGoBack?: () => void
}

export function NotFoundErrorPage({ onGoBack }: NotFoundErrorPageProps) {
  const translate = useTranslate()

  return (
    <PageRoot className={styles.container}>
      <div className={styles.internal}>
        <Heading variant="heading1" color="base">
          404 {translate('errors.pageNotFound')}
        </Heading>
        <Text color="weak" size="xs">
          {translate('errors.pageNotFound.message')}
        </Text>
        {onGoBack && (
          <Button size="lg" data-testid="go-back" onClick={onGoBack}>
            goBack
          </Button>
        )}
      </div>
    </PageRoot>
  )
}
