import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

export const createTelemetryService = (connectionString: string, enabled: boolean) => {
  const reactPlugin = new ReactPlugin()
  const appInsights = new ApplicationInsights({
    config: {
      connectionString,
      enableAutoRouteTracking: true,
      maxBatchInterval: 500,
      extensions: [reactPlugin]
    }
  })

  if (enabled) {
    appInsights.loadAppInsights()
  }

  return { reactPlugin, appInsights }
}
