/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GetOperationalYardZonesODataParams,
  HttpValidationProblemDetails,
  ODataOperationalYardZoneDtoArrayODataCollectionResponse
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class OperationalYardZones<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * No description
   *
   * @tags OperationalYardZones
   * @name GetOperationalYardZonesOData
   * @request GET:/api/v1/operational-yard-zones
   * @secure
   */
  getOperationalYardZonesOData = (
    query: GetOperationalYardZonesODataParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ODataOperationalYardZoneDtoArrayODataCollectionResponse,
      HttpValidationProblemDetails
    >({
      path: `/api/v1/operational-yard-zones`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
}
