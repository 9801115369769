import { groupBy } from 'lodash-es'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createQueryBuilderV4 } from '@odata2ts/odata-query-builder'
import { QODataOperationalSlotDto } from '@yms/api/odata-client/QItgPltYmsApi'
import { type ODataOperationalSlotDto } from '@yms/api/client/v1/data-contracts'
import { odataStringToRecord } from '@yms/api/odataStringToRecord'
import { OperationalSlots } from '@yms/api/client'
import { getClient } from '@yms/common/utils/attachClient'

import { type LocationPayload } from '../../useYardLocation'

const oerationalSlotsApi = new OperationalSlots(getClient())

/**
 * Removes disabled slots at the start and end of the list by column
 */
const spliceDisabledRows = (slots: ODataOperationalSlotDto[], slotsPerColumn: number) => {
  if (slotsPerColumn > 0) {
    let disabledSlotsAtStart = 0
    for (const slot of slots) {
      if (slot.isActive) {
        break
      }
      disabledSlotsAtStart++
    }

    if (disabledSlotsAtStart >= slotsPerColumn) {
      const rowsToRemove = Math.floor(disabledSlotsAtStart / slotsPerColumn)
      slots.splice(0, slotsPerColumn * rowsToRemove)
    }

    let disabledSlotsAtEnd = 0
    for (let i = slots.length - 1; i > 0; i--) {
      if (slots[i].isActive) {
        break
      }
      disabledSlotsAtEnd++
    }

    if (disabledSlotsAtEnd >= slotsPerColumn) {
      const rowsToRemove = Math.floor(disabledSlotsAtStart / slotsPerColumn)
      slots.splice(slots.length - slotsPerColumn * rowsToRemove, slotsPerColumn * rowsToRemove)
    }
  }
}

export const slots = {
  queryKey: ({ block, yardZone, site }: Partial<LocationPayload>) => [
    'slots',
    { context: { site, yardZone, block } }
  ],
  queryFn: async ({ signal, block, yardZone, site }: LocationPayload & { signal: AbortSignal }) => {
    if (!site) throw new Error('Missing site')
    if (!yardZone) throw new Error('Missing yardZone')
    if (!block) throw new Error('Missing block')

    const dto = new QODataOperationalSlotDto()
    const builder = createQueryBuilderV4('b', dto)

    const query = builder
      .filter(dto.siteCode.eq(site).and(dto.yardZoneName.eq(yardZone)).and(dto.blockName.eq(block)))
      .orderBy(dto.depth.desc())
      .build()

    const odata = odataStringToRecord(query)

    return oerationalSlotsApi
      .getOperationalSlotsOData({ filter: odata.filter, orderby: odata.orderby }, { signal })
      .then(r => r.data?.value ?? [])
  },
  select: (data: ODataOperationalSlotDto[], slotsPerColumn: number) => {
    const entries = Object.entries(groupBy(data, ({ depth }) => depth))
    entries.sort((a, b) => +b[0] - +a[0])

    for (const [, slots] of entries) {
      // First sort by rows, then by levels
      slots.sort((a, b) => {
        const rowDifference = ((a.row ?? 0) - (b.row ?? 0)) * slots.length
        const levelDifference = (a.level ?? 0) - (b.level ?? 0)
        return rowDifference - levelDifference
      })

      spliceDisabledRows(slots, slotsPerColumn)
    }

    return entries
  }
}

export const useSlotsQuery = (
  {
    block,
    yardZone,
    site
  }: {
    block?: string
    yardZone?: string
    site?: string
  },
  rows: number
) => {
  return useSuspenseQuery({
    queryKey: slots.queryKey({ site, yardZone, block }),
    queryFn: ({ signal }) => slots.queryFn({ signal, yardZone, block, site }),
    select: data => slots.select(data, rows)
  })
}
