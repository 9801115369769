import { useCallback } from 'react'
import { type PrimitiveType, useIntl } from 'react-intl'

export const useTranslate = () => {
  const intl = useIntl()

  return useCallback(
    (key?: string, props?: Record<string, PrimitiveType>, defaultMessage?: string) => {
      if (!key) return ''

      return intl.formatMessage({ id: key, defaultMessage: defaultMessage ?? key }, props)
    },
    [intl]
  )
}
