import { type ChangeEvent, forwardRef, useImperativeHandle, useRef } from 'react'
import type HxRadioButtonElement from '@katoennatie/hexagon/radio-button'

import { useListener } from './hooks/useListener'
import { type WebComponentProps } from './utils/WebComponent'
import { serializeForHexagon } from './utils/serializeForHexagon'
import { useTranslate } from './hooks/useTranslate'

interface RadioButtonProps extends WebComponentProps {
  name: string
  checked?: boolean
  disabled?: boolean
  value?: string
  readonly?: boolean
  error?: boolean
  helpText?: string
  errorText?: string
  label: string
  onChange?: (checked: boolean, value?: string) => void
}

function RadioButton(props: RadioButtonProps, ref: React.Ref<HxRadioButtonElement>) {
  const t = useTranslate()

  const radioRef = useRef<HxRadioButtonElement>()
  useImperativeHandle(ref, () => radioRef.current!, [radioRef])

  useListener(radioRef, 'hx-change', event => {
    const target = (event as unknown as ChangeEvent).target as HTMLInputElement
    props.onChange?.(target.checked, props.value)
  })

  return (
    <hx-radio-button
      ref={radioRef}
      data-testid={props['data-testid']}
      label={t(props.label)}
      name={serializeForHexagon(props.name)}
      checked={serializeForHexagon(props.checked)}
      disabled={serializeForHexagon(props.disabled)}
      value={serializeForHexagon(props.value)}
      readonly={serializeForHexagon(props.readonly)}
      error={serializeForHexagon(props.error)}
      help-text={serializeForHexagon(props.helpText)}
      error-text={serializeForHexagon(props.errorText)}
    ></hx-radio-button>
  )
}

const RadioButtonRef = forwardRef(RadioButton)

export { RadioButtonRef as RadioButton }
