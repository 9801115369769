import { IntlProvider } from 'react-intl'
import { RouterProvider } from 'react-router-dom'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { DialogProvider } from '@yms/common/providers/DialogProvider'

import { persister, queryClient } from './App.queryClient'
import { router } from './App.router'
import { en } from './i18n'
import { SnackbarProvider } from './core/Hexagon/Snackbar'
import { PersistGate } from './core/PersistGate'
import { ReloadPrompt } from './core/ReloadPrompt'

function App() {
  return (
    <IntlProvider locale="en" messages={en}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
        onSuccess={async () => {
          await queryClient.resumePausedMutations()
          queryClient.invalidateQueries()
        }}
      >
        <PersistGate>
          <ReactQueryDevtools
            position="right"
            buttonPosition="bottom-right"
            initialIsOpen={false}
          />
          <RouterProvider router={router} />
          <SnackbarProvider />
          <ReloadPrompt />
          <DialogProvider />
        </PersistGate>
      </PersistQueryClientProvider>
    </IntlProvider>
  )
}

export default App
