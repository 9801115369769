import '@katoennatie/hexagon/alert-dialog'

import { type ReactNode, useState } from 'react'
import { type PrimitiveType } from 'react-intl'
import { produce } from 'immer'
import { type Dialog, Portal, Dialog as HxDialog } from '@yms/hexagon'
import { serializeForHexagon } from '@yms/hexagon/utils/serializeForHexagon'

import { dispatchAction, useAction } from '../Actions'
import { useTranslate } from '../hooks/useTranslate'

export interface Dialog {
  readonly id: string
  readonly open?: boolean
  readonly title: string
  readonly message: string
  readonly messageProps?: Record<string, PrimitiveType>
  readonly content: ReactNode
  readonly type?: 'alert' | 'normal'
}

export const DialogProvider = () => {
  const translate = useTranslate()
  const [dialogs, setDialogs] = useState<Dialog[]>([])

  useAction<Dialog>('dialog:open', payload => {
    setDialogs(dialogs => [...dialogs, { open: true, type: 'alert', ...payload }])
  })

  useAction<string>('dialog:close', id => {
    setDialogs(
      produce(dialogs => {
        const index = dialogs.findIndex(dialog => dialog.id === id)
        if (index !== -1) {
          dialogs[index].open = false
        }
      })
    )

    setTimeout(() => {
      setDialogs(dialogs => dialogs.filter(dialog => dialog.id !== id))
    }, 350) // 350ms transition delay
  })

  return (
    <Portal container={() => document.getElementById('modal-root')}>
      {dialogs.map(dialog =>
        dialog.type === 'alert' ? (
          <hx-alert-dialog
            open={serializeForHexagon(dialog.open)}
            key={dialog.id}
            id={dialog.id}
            heading={translate(dialog.title)}
            message={translate(dialog.message, dialog.messageProps)}
          >
            {dialog.content}
          </hx-alert-dialog>
        ) : (
          <HxDialog
            disablePortal
            open={dialog.open}
            key={dialog.id}
            heading={dialog.title}
            message={translate(dialog.message, dialog.messageProps)}
            onClose={() => dispatchAction('dialog:close', dialog.id)}
          >
            {dialog.content}
          </HxDialog>
        )
      )}
    </Portal>
  )
}
