import { type ReactNode } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TextProps = Record<string, any>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function executeEvent(data: any) {
  document.dispatchEvent(new CustomEvent('snackbar:open', { detail: data }))
}

export const snackbar = {
  info: function info(message?: string, textProps?: TextProps, action?: ReactNode) {
    executeEvent({ type: 'info', message, textProps, action })
  },

  success: function success(message?: string, textProps?: TextProps, action?: ReactNode) {
    executeEvent({ type: 'success', message, textProps, action })
  },

  warning: function warning(message?: string, textProps?: TextProps, action?: ReactNode) {
    executeEvent({ type: 'warning', message, textProps, action })
  },

  error: function error(message?: string, textProps?: TextProps, action?: ReactNode) {
    executeEvent({ type: 'error', message, textProps, action })
  }
}
