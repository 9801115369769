import { useSuspenseQuery } from '@tanstack/react-query'

import { operatorsApi } from '@mobile/api/operators'

export const getCurrentOperator = async ({ signal }: { signal: AbortSignal }) => {
  const r = await operatorsApi.getCurrentOperator({ signal })

  return {
    operatorId: r.data.operatorId!,
    yardZoneIds: (r.data.sites?.flatMap(s => s.yardZones?.map(yz => yz.id)) ?? []) as string[],
    handlerId: r.data.handler?.id ?? null,
    yardZones:
      r.data.sites?.flatMap(s =>
        (s.yardZones ?? []).map(yz => ({ ...yz, siteCode: s.code, siteName: s.name }))
      ) ?? [],
    tables: r.data.tables ?? {}
  }
}

type CurrentOperator = Awaited<ReturnType<typeof getCurrentOperator>>

export const currentOperator = {
  queryKey: ['current-operator'],
  queryFn: getCurrentOperator
}

type UseCurrentOperatorQueryOptions<T> = {
  select?: (data: CurrentOperator) => T
}

export const useCurrentOperatorQuery = <T = CurrentOperator>({
  select
}: UseCurrentOperatorQueryOptions<T> = {}) => {
  const query = useSuspenseQuery({
    queryKey: currentOperator.queryKey,
    queryFn: currentOperator.queryFn,
    select
  })

  return query.data
}
