import { forwardRef, useRef, useImperativeHandle } from 'react'
import type HxButton from '@katoennatie/hexagon/button'

import { serializeForHexagon } from '../utils/serializeForHexagon'
import { useListener } from '../hooks/useListener'

export type ButtonProps = {
  readonly 'data-testid'?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly children: any
  readonly onClick?: () => void
  readonly type?: HxButton['type']
  readonly form?: HxButton['form']
  readonly className?: string
  readonly variant?: HxButton['variant']
  readonly loading?: HxButton['loading']
  readonly size?: HxButton['size']
  readonly slot?: HxButton['slot']
  readonly disabled?: HxButton['disabled']
  readonly fullWidth?: HxButton['fullWidth']
}

const ButtonRef = forwardRef<HxButton, ButtonProps>(function Button(
  {
    className,
    'data-testid': dataTestId,
    onClick,
    children,
    type,
    form,
    loading,
    size,
    variant = 'secondary',
    disabled,
    fullWidth,
    slot
  }: ButtonProps,
  ref
) {
  const buttonRef = useRef<HxButton>()
  useImperativeHandle(ref, () => buttonRef.current!, [buttonRef])
  useListener(buttonRef, 'click', onClick)

  return (
    <hx-button
      ref={buttonRef}
      slot={serializeForHexagon(slot)}
      variant={serializeForHexagon(variant)}
      class={serializeForHexagon(className)}
      size={serializeForHexagon(size)}
      type={serializeForHexagon(type)}
      form={serializeForHexagon(form)}
      full-width={serializeForHexagon(fullWidth)}
      data-testid={serializeForHexagon(dataTestId)}
      loading={serializeForHexagon(loading)}
      disabled={serializeForHexagon(disabled)}
    >
      {children}
    </hx-button>
  )
})

export { ButtonRef as Button }
