import { forwardRef, useImperativeHandle, useRef } from 'react'
import type HxIconButton from '@katoennatie/hexagon/icon-button'

import { serializeForHexagon } from './utils/serializeForHexagon'

export type IconButtonProps = {
  readonly 'data-testid'?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly children: any
  readonly onClick?: () => void
  readonly type?: HxIconButton['type']
  readonly form?: HxIconButton['form']
  readonly className?: string
  readonly variant?: HxIconButton['variant']
  readonly loading?: HxIconButton['loading']
  readonly size?: HxIconButton['size']
  readonly slot?: HxIconButton['slot']
  readonly disabled?: HxIconButton['disabled']
}

const IconButtonRef = forwardRef<HxIconButton, IconButtonProps>(function IconButton(
  {
    className,
    'data-testid': dataTestId,
    onClick,
    children,
    type,
    form,
    loading,
    size = 'lg',
    variant = 'secondary',
    disabled,
    slot
  }: IconButtonProps,
  ref
) {
  const buttonRef = useRef<HxIconButton>()

  useImperativeHandle(ref, () => buttonRef.current!, [buttonRef])

  return (
    <hx-icon-button
      ref={buttonRef}
      onClick={onClick}
      slot={serializeForHexagon(slot)}
      variant={serializeForHexagon(variant)}
      class={serializeForHexagon(className)}
      size={serializeForHexagon(size)}
      type={serializeForHexagon(type)}
      form={serializeForHexagon(form)}
      data-testid={serializeForHexagon(dataTestId)}
      loading={serializeForHexagon(loading)}
      disabled={serializeForHexagon(disabled)}
    >
      {children}
    </hx-icon-button>
  )
})

export { IconButtonRef as IconButton }
