import clsx from 'clsx'

import styles from './PageRoot.module.css'

type PageRootProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly children: any
  readonly alternate?: boolean
  readonly className?: string
  readonly table?: boolean
}

export function PageRoot({ children, alternate, table, className }: PageRootProps) {
  return (
    <div
      className={clsx(
        'content-transition',
        styles.pageRoot,
        { [styles.alternate]: alternate, [styles.table]: table },
        className
      )}
    >
      {children}
    </div>
  )
}
