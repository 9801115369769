import type HxText from '@katoennatie/hexagon/text'

import { serializeForHexagon } from '../utils/serializeForHexagon'
import { useTranslate } from '../hooks/useTranslate'
import styles from './Text.module.css'

export type TextProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly children: any
  readonly variant?: HxText['variant']
  readonly color?: HxText['color']
  readonly size?: HxText['size']
  readonly class?: string
  readonly bold?: boolean
}

export function Text({ variant, size, color, class: _class, children, bold }: TextProps) {
  const t = useTranslate()
  const content = children || '-'

  const result = typeof children === 'string' ? t(content) : content

  return (
    <hx-text
      class={serializeForHexagon(_class)}
      variant={serializeForHexagon(variant)}
      size={serializeForHexagon(size)}
      color={serializeForHexagon(color)}
    >
      {bold ? <b className={styles.bold}>{result}</b> : result}
    </hx-text>
  )
}
