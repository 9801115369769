import { useQuery } from '@tanstack/react-query'
import { createQueryBuilderV4 } from '@odata2ts/odata-query-builder'
import { QODataBlockDto } from '@yms/api/odata-client/QItgPltYmsApi'
import { odataStringToRecord } from '@yms/api/odataStringToRecord'
import { type BlockDetailsDto, Blocks } from '@yms/api/client'
import { getClient } from '@yms/common/utils/attachClient'

const blocksApi = new Blocks(getClient())

export const blockDetail = {
  queryKey: ({ block, site, yardZone }: { site?: string; block?: string; yardZone?: string }) => [
    'block',
    { entity: block, context: { site, yardZone } }
  ],
  queryFn: async ({
    signal,
    site,
    yardZone,
    block
  }: {
    site?: string
    block?: string
    yardZone?: string
    signal: AbortSignal
  }) => {
    if (!block || !yardZone || !site) throw new Error('Missing block information')

    const dto = new QODataBlockDto()
    const builder = createQueryBuilderV4('b', dto)

    const query = builder
      .select('id')
      .top(1)
      .filter(dto.yardZoneName.eq(yardZone).and(dto.name.eq(block)).and(dto.siteCode.eq(site)))
      .build()

    const odata = odataStringToRecord(query)

    const blockId = await blocksApi
      .getODataBlocks(
        {
          select: odata.select,
          top: odata.top,
          filter: odata.filter
        },
        { signal }
      )
      .then(r => r.data?.value?.[0]?.id)

    if (!blockId) throw new Error('Missing blockId')

    return blocksApi.getById(blockId).then(r => r.data)
  }
}

export const useBlockDetailsQuery = ({
  site,
  block,
  yardZone
}: { site?: string; block?: string; yardZone?: string } = {}) => {
  return useQuery({
    enabled: !!(block && yardZone),
    queryKey: blockDetail.queryKey({ site, yardZone, block }),
    placeholderData: { id: '', levels: 3 } as BlockDetailsDto,
    queryFn: ({ signal }) => blockDetail.queryFn({ signal, site, yardZone, block }),
    select(data) {
      return { id: data.id, levels: data.levels ?? 3, isActive: data.isActive }
    }
  })
}
