import { useSuspenseQuery } from '@tanstack/react-query'
import { get } from 'idb-keyval'
import { type TableSettings } from '@yms/common/components/Table/Table.types'

export const getTableKey = ({ ownerId, name }: { name: string; ownerId: string }) => {
  return `${ownerId}.table-v2.${name}`
}

export const tableSettings = {
  queryKey: ({ name, ownerId: operatorId }: { name: string; ownerId: string }) => [
    'local-settings',
    'table',
    { name, operatorId }
  ],
  queryFn: async ({ name, ownerId }: { name: string; ownerId: string }) => {
    const settings = await get<TableSettings>(getTableKey({ name, ownerId }))
    const pageIndex =
      Number.parseInt(sessionStorage.getItem(getTableKey({ name, ownerId })) ?? '0') || 0

    if (!settings) {
      return {
        views: [],
        pagination: {
          pageSize: 25,
          pageIndex
        }
      }
    }

    settings.pagination ??= {
      pageSize: 25,
      pageIndex
    }

    settings.pagination.pageIndex = pageIndex

    settings.views.sort((a, b) => (a.isProtected ? -1 : b.isProtected ? 1 : 0))
    return settings
  }
}

export const useTableSettingsQuery = ({ name, ownerId }: { name: string; ownerId: string }) => {
  const { data: state } = useSuspenseQuery({
    queryKey: tableSettings.queryKey({ name, ownerId }),
    queryFn: () => tableSettings.queryFn({ name, ownerId })
  })

  return state
}
