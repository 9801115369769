import { useRouteError } from 'react-router-dom'
import { Heading } from '@yms/hexagon'

import { ErrorPage } from './core/ErrorPage/ErrorPage'
import { getErrorMessage } from './core/ErrorPage/getErrorMessage'
import styles from './App.ErrorElement.module.css'

export function ErrorElement() {
  const error = useRouteError()

  return (
    <div className={styles.box}>
      <div className={styles.end}>
        <ErrorPage title="errors.unexpectedError" subtitle="errors.unexpectedError.message" />
      </div>
      <div className={styles.center}>
        <div className={styles.stackTrace}>
          <Heading variant="heading4" color="weak">
            Stack trace
          </Heading>
          {getErrorMessage(error)}
        </div>
      </div>
    </div>
  )
}
