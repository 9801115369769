import { type ReactNode } from 'react'
import type HxInlineAlert from '@katoennatie/hexagon/inline-alert'

import { type WebComponentProps } from './utils/WebComponent'
import { serializeForHexagon } from './utils/serializeForHexagon'

export interface InlineAlertProps extends WebComponentProps {
  readonly children?: ReactNode
  readonly variant?: HxInlineAlert['variant']
  readonly className?: string
  readonly heading?: string
  readonly description?: string
}

export const InlineAlert = ({
  children,
  className,
  heading,
  description,
  variant = 'warning'
}: InlineAlertProps) => {
  return (
    <div className={className}>
      <hx-inline-alert
        variant={variant}
        heading={heading}
        description={serializeForHexagon(description)}
      >
        {children}
      </hx-inline-alert>
    </div>
  )
}
