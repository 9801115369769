import { setLocale } from 'yup'

export function setupYupLocale() {
  setLocale({
    mixed: {
      notType: ({ label }) => ({
        textKey: 'fieldInvalid',
        textProps: { label }
      }),
      default: ({ label }) => ({
        textKey: 'fieldInvalid',
        textProps: { label }
      }),
      required: ({ label }) => ({
        textKey: 'fieldRequired',
        textProps: { label }
      }),
      defined: ({ label }) => ({
        textKey: 'fieldRequired',
        textProps: { label }
      })
    },
    array: {
      min: ({ label, min }) => ({
        textKey: 'fieldTooShort',
        textProps: { label, min }
      }),
      max: ({ label, max }) => ({
        textKey: 'fieldTooBig',
        textProps: { label, max }
      })
    },
    string: {
      min: ({ label, min }) => ({
        textKey: 'fieldTooShort',
        textProps: { label, min }
      }),
      max: ({ label, max }) => ({
        textKey: 'fieldTooLong',
        textProps: { label, max }
      }),
      email: ({ label }) => ({
        textKey: 'fieldMustBeValidEmail',
        textProps: { label }
      })
    },
    number: {
      min: ({ label, min }) => ({
        textKey: 'fieldTooShort',
        textProps: { label, min }
      }),
      max: ({ label, max }) => ({
        textKey: 'fieldTooBig',
        textProps: { label, max }
      })
    }
  })
}
