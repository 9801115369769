import { get } from 'lodash-es'
import { QFilterExpression, createQueryBuilderV4 } from '@odata2ts/odata-query-builder'
import { QDateTimeOffsetPath, QOrderByExpression } from '@odata2ts/odata-query-objects'
import { QBasePath } from '@odata2ts/odata-query-objects/lib/path/base/QBasePath'
import { type YardTaskProjection } from '@yms/api/odata-client/ItgPltYmsApiModel'
import { QYardTaskProjection } from '@yms/api/odata-client/QItgPltYmsApi'
import { YardTaskStatus } from '@yms/api/client/v1/data-contracts'
import { odataStringToRecord } from '@yms/api/odataStringToRecord'
import { arrayToEqualsFilter } from '@yms/api/odataUtilities'
import {
  type UserContext,
  type Fetcher,
  type Filter,
  type FetcherParameters
} from '@yms/common/components/Table/Table.types'
import dayjs from 'dayjs'

import {
  searchInstance,
  type AzureSearchFacets
} from '@mobile/api/azure-search-client/AzureSearchClient'

const pushLocationFilter = (location: UserContext['location'], filters: QFilterExpression[]) => {
  const yardTask = new QYardTaskProjection()
  let locationsFilter
  for (const [siteCode, yardZoneNames] of location) {
    let yardZoneFilter = null
    for (const yardZone of yardZoneNames) {
      const filter = new QFilterExpression(`search.ismatch('"${yardZone}"')`)
      yardZoneFilter = yardZoneFilter ? yardZoneFilter.or(filter) : filter
    }

    const locationFilter = yardTask.siteCode.eq(siteCode).and(yardZoneFilter)
    locationsFilter = locationsFilter ? locationsFilter.or(locationFilter) : locationFilter
  }

  filters.push(locationsFilter!)
}

const pushFinishedOnFilter = (
  property: QDateTimeOffsetPath,
  filter: Filter,
  filters: QFilterExpression[]
) => {
  if (filter.name === 'finishedOn') {
    filters.push(
      property.ge(
        dayjs
          .utc()
          .startOf('day')
          .subtract(dayjs.duration(filter.value[0] as string))
          .toISOString()
      )
    )
  }
}

const pushYardFromFilter = (
  filter: Filter,
  filters: QFilterExpression[],
  contextYardZones: string[],
  yardTask: QYardTaskProjection
) => {
  if (filter.name === 'yardFrom') {
    const filtered = filter.value.filter(yz => contextYardZones.includes(yz as string)) as string[]
    filters.push(
      arrayToEqualsFilter(yardTask.fromLocationDetails.getEntity(true).yardZone, filtered)
    )
  }
}

const pushYardToFilter = (
  filter: Filter,
  filters: QFilterExpression[],
  contextYardZones: string[],
  yardTask: QYardTaskProjection
) => {
  if (filter.name === 'yardTo') {
    const filtered = filter.value.filter(yz => contextYardZones.includes(yz as string)) as string[]

    filters.push(
      arrayToEqualsFilter(yardTask.plannedToLocationDetails.getEntity(true).yardZone, filtered)
        .or(
          arrayToEqualsFilter(
            yardTask.alternativePlannedToLocationDetails.getEntity(true).yardZone,
            filtered
          )
        )
        .or(
          arrayToEqualsFilter(
            yardTask.operatorsPlannedToLocationDetails.getEntity(true).yardZone,
            filtered
          )
        )
        .or(
          arrayToEqualsFilter(yardTask.actualToLocationDetails.getEntity(true).yardZone, filtered)
        )
    )
  }
}

export const fetcher: Fetcher<YardTaskProjection, AzureSearchFacets> = ({
  signal,
  sorting,
  userContext: { handlerType, location },
  filtering
}) => {
  const yardTask = new QYardTaskProjection()
  const builder = createQueryBuilderV4('y', yardTask)
  const filters: QFilterExpression[] = []

  pushLocationFilter(location, filters)

  if (handlerType) {
    filters.push(yardTask.handlerType.eq(handlerType))
  }

  /**
   * We need to constrain existing filter to inside user context as a precaution
   */
  const contextYardZones = location.flatMap(([, yardZones]) => yardZones)

  for (const filter of filtering) {
    switch (filter.type) {
      case 'equals-radio':
      case 'equals': {
        const taskProperty = get(yardTask, filter.name)
        if (taskProperty instanceof QDateTimeOffsetPath) {
          pushFinishedOnFilter(taskProperty, filter, filters)
        } else if (taskProperty instanceof QBasePath) {
          filters.push(arrayToEqualsFilter(taskProperty, filter.value))
        }

        pushYardFromFilter(filter, filters, contextYardZones, yardTask)
        pushYardToFilter(filter, filters, contextYardZones, yardTask)
      }
    }
  }

  const sort: QOrderByExpression[] =
    sorting?.map(sort => new QOrderByExpression(`${sort.id} ${sort.desc ? 'desc' : 'asc'}`)) ?? []

  const query = builder
    .filter(...filters)
    .orderBy(...sort)
    .top(1000)
    .count(true)
    .build()
  const odataRecord = odataStringToRecord(query)

  return searchInstance<YardTaskProjection>(
    'yard-tasks',
    {
      queryType: 'full',
      searchMode: 'any',
      $count: odataRecord.count,
      $filter: odataRecord.filter,
      $orderby: odataRecord.orderby,
      $top: odataRecord.top,
      facet: []
    },
    signal
  ).then(data => ({
    rows: data.value,
    count: data['@odata.count'],
    facets: data['@search.facets']
  }))
}

export const getCountFetcher = ({
  signal,
  userContext: { handlerType, location },
  filtering
}: FetcherParameters) => {
  const filters: QFilterExpression[] = []

  const yardTask = new QYardTaskProjection()
  //! Only 'Queued' tasks are counted
  filters.push(arrayToEqualsFilter(yardTask.status, [YardTaskStatus.Queued]))
  /**
   * We need to constrain existing filter to inside user context as a precaution
   */
  const contextYardZones = location.flatMap(([, yardZones]) => yardZones)

  if (handlerType) {
    filters.push(yardTask.handlerType.eq(handlerType))
  }

  pushLocationFilter(location, filters)

  for (const filter of filtering) {
    switch (filter.type) {
      case 'equals-radio':
      case 'equals': {
        const taskProperty = get(yardTask, filter.name)
        if (taskProperty instanceof QDateTimeOffsetPath) {
          pushFinishedOnFilter(taskProperty, filter, filters)
        } else if (taskProperty instanceof QBasePath) {
          filters.push(arrayToEqualsFilter(taskProperty, filter.value))
        }

        pushYardFromFilter(filter, filters, contextYardZones, yardTask)
        pushYardToFilter(filter, filters, contextYardZones, yardTask)
      }
    }
  }

  const query = createQueryBuilderV4('y', yardTask)
    .filter(...filters)
    .top(0)
    .count()
    .build()
  const odataRecord = odataStringToRecord(query)

  return searchInstance<YardTaskProjection>(
    'yard-tasks',
    {
      queryType: 'full',
      searchMode: 'any',
      $count: odataRecord.count,
      $filter: odataRecord.filter,
      $top: odataRecord.top
    },
    signal
  ).then(data => data['@odata.count'])
}
