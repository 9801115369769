export function MobileLogo() {
  return (
    <svg width="61" height="67" viewBox="0 0 73 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M67.4011 14.7862L41.1388 1.09662C38.3337 -0.365537 34.9996 -0.36554 32.1946 1.09661L5.93221 14.7862C2.69792 16.4721 0.666687 19.8364 0.666687 23.5073V56.4927C0.666687 60.1636 2.69792 63.5279 5.93221 65.2138L32.1946 78.9034C34.9996 80.3655 38.3337 80.3655 41.1388 78.9034L67.4011 65.2138C70.6354 63.5279 72.6667 60.1636 72.6667 56.4927V23.5073C72.6667 19.8364 70.6354 16.4721 67.4011 14.7862Z"
        fill="#DB0E16"
      />
      <g clipPath="url(#clip0_1187_31091)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.72 24.1524C50.3136 22.7741 48.4058 21.9999 46.4167 21.9999C44.4277 21.9999 42.5199 22.7741 41.1134 24.1524C39.7068 25.5306 38.9167 27.3998 38.9167 29.349C38.9167 34.8424 46.4167 42.9999 46.4167 42.9999C46.4167 42.9999 53.9167 34.8424 53.9167 29.349C53.9167 27.3998 53.1266 25.5306 51.72 24.1524ZM50.5835 29.8749C50.5835 32.2911 48.718 34.2499 46.4167 34.2499C44.1155 34.2499 42.25 32.2911 42.25 29.8749C42.25 27.4586 44.1155 25.4998 46.4167 25.4998C48.718 25.4998 50.5835 27.4586 50.5835 29.8749Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.4167 30.9999C31.4167 34.3136 28.7304 36.9999 25.4167 36.9999C22.103 36.9999 19.4167 34.3136 19.4167 30.9999C19.4167 27.6861 22.103 24.9999 25.4167 24.9999C28.7304 24.9999 31.4167 27.6861 31.4167 30.9999ZM28.4167 30.9999C28.4167 32.6567 27.0736 33.9999 25.4167 33.9999C23.7598 33.9999 22.4167 32.6567 22.4167 30.9999C22.4167 29.343 23.7598 27.9999 25.4167 27.9999C27.0736 27.9999 28.4167 29.343 28.4167 30.9999Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4167 57.9999C28.7304 57.9999 31.4167 55.3136 31.4167 51.9999C31.4167 48.6861 28.7304 45.9999 25.4167 45.9999C22.103 45.9999 19.4167 48.6861 19.4167 51.9999C19.4167 55.3136 22.103 57.9999 25.4167 57.9999ZM25.4167 54.9999C27.0736 54.9999 28.4167 53.6567 28.4167 51.9999C28.4167 50.343 27.0736 48.9999 25.4167 48.9999C23.7598 48.9999 22.4167 50.343 22.4167 51.9999C22.4167 53.6567 23.7598 54.9999 25.4167 54.9999Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.4167 51.9999C52.4167 55.3136 49.7304 57.9999 46.4167 57.9999C43.1031 57.9999 40.4167 55.3136 40.4167 51.9999C40.4167 48.6861 43.1031 45.9999 46.4167 45.9999C49.7304 45.9999 52.4167 48.6861 52.4167 51.9999ZM49.4167 51.9999C49.4167 53.6567 48.0737 54.9999 46.4167 54.9999C44.7598 54.9999 43.4167 53.6567 43.4167 51.9999C43.4167 50.343 44.7598 48.9999 46.4167 48.9999C48.0737 48.9999 49.4167 50.343 49.4167 51.9999Z"
          fill="white"
        />
        <path d="M26.9167 38.4999V44.4999H23.9167V38.4999H26.9167Z" fill="white" />
        <path d="M32.9167 53.4999H38.9167V50.4999H32.9167V53.4999Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1187_31091">
          <rect width="36" height="36" fill="white" transform="translate(18.6667 21.9999)" />
        </clipPath>
      </defs>
    </svg>
  )
}
