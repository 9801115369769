import { type UserManagerSettings, WebStorageStateStore, UserManager } from 'oidc-client-ts'

import {
  AUTH_AUTHORITY,
  AUTH_BASE_URL,
  AUTH_IDENTITY_SERVER_CLIENT_ID,
  AUTH_SCOPE
} from '../App.env'
import { IdbAsyncStorage } from '../core/AsyncStorage/IdbAsyncStorage'

const IDENTITY_SERVER_CONFIG: UserManagerSettings = {
  authority: AUTH_AUTHORITY,
  client_id: AUTH_IDENTITY_SERVER_CLIENT_ID,
  redirect_uri: `${AUTH_BASE_URL}auth/sign-in`,
  silent_redirect_uri: `${AUTH_BASE_URL}auth/sign-in-silent`,
  post_logout_redirect_uri: `${AUTH_BASE_URL}auth/logout`,
  scope: `offline_access profile openid email ${AUTH_SCOPE}`,
  loadUserInfo: true,
  response_type: 'code',
  response_mode: 'query',
  userStore: new WebStorageStateStore({ store: new IdbAsyncStorage() }),
  monitorSession: true
}

export const userManager = new UserManager(IDENTITY_SERVER_CONFIG)
