import { useSuspenseQuery } from '@tanstack/react-query'
import { createQueryBuilderV4 } from '@odata2ts/odata-query-builder'
import { QODataOperationalBlockDto } from '@yms/api/odata-client/QItgPltYmsApi'
import { odataStringToRecord } from '@yms/api/odataStringToRecord'
import { OperationalBlocks } from '@yms/api/client'
import { getClient } from '@yms/common/utils/attachClient'

const operationalBlocksApi = new OperationalBlocks(getClient())

export const blocks = {
  queryKey: ({ site, yardZone }: { site?: string; yardZone?: string }) => [
    'blocks',
    { entity: yardZone, context: site }
  ],
  queryFn: async ({
    signal,
    site,
    yardZone
  }: {
    signal: AbortSignal
    site?: string
    yardZone?: string
  }) => {
    if (!yardZone || !site) throw new Error('Missing yardZone or site')

    const dto = new QODataOperationalBlockDto()
    const builder = createQueryBuilderV4('b', dto)
    const query = builder
      .filter(dto.isActive.eq(true), dto.siteCode.eq(site).and(dto.yardZoneName.eq(yardZone)))
      .orderBy(dto.name.asc())
      .build()
    const odata = odataStringToRecord(query)

    return operationalBlocksApi
      .getOperationalBlocksOData(odata, { signal })
      .then(r => r.data?.value ?? [])
  }
}

export const useBlocksQuery = ({ site, yardZone }: { site?: string; yardZone?: string }) => {
  return useSuspenseQuery({
    queryKey: blocks.queryKey({ site, yardZone }),
    queryFn: ({ signal }) => blocks.queryFn({ signal, site, yardZone })
  })
}
