import { Link, matchPath, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { Icon, Text } from '@yms/hexagon'

import styles from './Menu.module.css'

type MenuItemProps = {
  readonly icon: string
  readonly title: string
  readonly path: string
}

export function MenuItem({ path, icon, title }: MenuItemProps) {
  const { pathname } = useLocation()
  const active = !!matchPath({ path, end: false }, pathname)

  return (
    <Link
      to={path}
      data-testid={path}
      unstable_viewTransition
      className={clsx(styles.item, { [styles.itemActive]: active })}
    >
      <div className={styles.itemContent}>
        <Icon name={icon} size="lg" />
        <Text variant="body" bold>
          {title}
        </Text>
      </div>
    </Link>
  )
}
