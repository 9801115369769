/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  HttpValidationProblemDetails,
  SendNotificationPayload,
  SubscribePayload
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class PushNotifications<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * No description
   *
   * @tags PushNotifications
   * @name Subscribe
   * @summary Subscribes the currently authorized user to push-notifications.
   * @request POST:/api/v1/push-notifications/subscriptions
   * @secure
   */
  subscribe = (data: SubscribePayload, params: RequestParams = {}) =>
    this.http.request<string, HttpValidationProblemDetails>({
      path: `/api/v1/push-notifications/subscriptions`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags PushNotifications
   * @name CancelSubscription
   * @summary Cancels an existing push-notification subscription.
   * @request POST:/api/v1/push-notifications/subscriptions/{subscriptionId}/cancel
   * @secure
   */
  cancelSubscription = (subscriptionId: string, params: RequestParams = {}) =>
    this.http.request<void, HttpValidationProblemDetails>({
      path: `/api/v1/push-notifications/subscriptions/${subscriptionId}/cancel`,
      method: 'POST',
      secure: true,
      ...params
    })
  /**
   * No description
   *
   * @tags PushNotifications
   * @name SendNotification
   * @summary Sends a push-notification that can be used for debugging or troubleshooting purposes.
   * @request POST:/api/v1/push-notifications/test
   * @secure
   */
  sendNotification = (data: SendNotificationPayload, params: RequestParams = {}) =>
    this.http.request<void, HttpValidationProblemDetails>({
      path: `/api/v1/push-notifications/test`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    })
}
