import { type ReactNode, useId, useRef } from 'react'
import { type PrimitiveType } from 'react-intl'
import type HxDialog from '@katoennatie/hexagon/dialog'

import { useTranslate } from '../hooks/useTranslate'
import { Portal } from '../Portal'
import { serializeForHexagon } from '../utils/serializeForHexagon'
import { useListener } from '../hooks/useListener'

export type DialogProps = {
  readonly open: HxDialog['open']
  readonly heading: HxDialog['heading']
  readonly message: HxDialog['message']
  readonly messageProps?: Record<string, PrimitiveType>
  readonly onClose?: () => void
  readonly children: ReactNode
  readonly disablePortal?: boolean
  readonly container?: Element | (() => Element | null) | null
}

export function Dialog({
  heading,
  message,
  messageProps,
  open,
  children,
  onClose,
  disablePortal,
  container
}: DialogProps) {
  const id = useId()
  const ref = useRef<HxDialog>()
  const translate = useTranslate()

  useListener(ref, 'hx-close', onClose)

  return (
    <Portal disablePortal={disablePortal} container={container}>
      {disablePortal || container ? (
        <hx-dialog
          id={id}
          ref={ref}
          open={serializeForHexagon(open)}
          heading={translate(heading)}
          message={translate(message, messageProps)}
        >
          {children}
        </hx-dialog>
      ) : (
        <div>
          <hx-dialog
            id={id}
            ref={ref}
            open={serializeForHexagon(open)}
            heading={translate(heading)}
            message={translate(message, messageProps)}
          >
            {children}
          </hx-dialog>
        </div>
      )}
    </Portal>
  )
}
