import { type SortingState } from '@tanstack/react-table'
import {
  QFilterExpression,
  QOrderByExpression,
  type UrlExpressionValueModel
} from '@odata2ts/odata-query-objects'
import { type QBasePath } from '@odata2ts/odata-query-objects/lib/path/base/QBasePath'

const sanitizeUserInput = (query: string) => {
  const specialChars = '-+&|(){}[]^~*?!:\\/'
  const escaped = specialChars.split('')

  let result = query
  for (const char of escaped) {
    result = result.replaceAll(char, ` `)
  }

  return result
}

/**
 * Parses search to lucene regex for prefix/suffix search
 * @param search search query
 * @returns
 */
export const serializeSearch = (search?: string): string => {
  if (!search) return ''

  const luceneRegex = search
    .trim()
    .split(' ')
    .map(word => `.*${sanitizeUserInput(word)}.*`)
    .join('|')

  return `/${luceneRegex}/`
}

/**
 * Utility function to concatenate array of values for the same property
 * @param values collection of values
 * @param filterExpression filter expression to use
 * @returns
 */
export const concatenateWithOr = <T>(
  values: T[],
  filterExpression: (value: T) => QFilterExpression
) => {
  let result = new QFilterExpression()
  const [first, ...rest] = values

  const modifier = (value: T) => {
    if (typeof value === 'string') {
      return value.replaceAll("'", "''") as T
    }

    return value
  }

  result = result.or(filterExpression(modifier(first)))
  for (const filter of rest) {
    result = result.or(filterExpression(modifier(filter)))
  }

  return result
}

/**
 * Feel free to propose better name for this!
 * @param path path that this query string should be created for
 * @param values collection of values that should be used
 * @returns QFilterExpression you can push to filter array
 */
export const arrayToEqualsFilter = <T extends UrlExpressionValueModel>(
  path: QBasePath<T, T>,
  values: T[]
) => {
  return concatenateWithOr(values, path.eq.bind(path))
}

/**
 *
 * @param sorting Note we only support one row sorting
 * @returns orderBy you can pass to builder
 */

export const serializeSorting = (sorting?: SortingState) => {
  if (sorting && sorting.length > 0) {
    return sorting.map(sort => new QOrderByExpression(`${sort.id} ${sort.desc ? 'desc' : 'asc'}`))
  }

  return [null]
}
