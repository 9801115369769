export const registerAction = <T>(name: string, handler: (data: T) => Promise<unknown> | void) => {
  const eventHandler = (event: Event) => {
    const customEvent = event as CustomEvent<T>
    handler(customEvent.detail)
  }

  document.addEventListener(`action:${name}`, eventHandler)

  const deregister = () => document.removeEventListener(`action:${name}`, eventHandler)
  return deregister
}
