import { error, info } from '../logging'

export const getLocaleImport = (locale: string) => {
  /**
   * Since some browsers return for example: pl-pl instead of pl
   * we have to only truncate duplicated keys to singlar segment
   **/
  let localeString = locale.toLocaleLowerCase()
  if (localeString.includes('-')) {
    const uniqueSegments = [...new Set(localeString.split('-'))]
    if (uniqueSegments.length === 1) {
      localeString = uniqueSegments[0]
    }
  }

  info(`[getLocaleImport] ${locale} ${localeString}`)

  switch (localeString) {
    case 'af':
      return import('dayjs/locale/af')
    case 'ar-dz':
      return import('dayjs/locale/ar-dz')
    case 'am':
      return import('dayjs/locale/am')
    case 'ar-iq':
      return import('dayjs/locale/ar-iq')
    case 'ar-kw':
      return import('dayjs/locale/ar-kw')
    case 'ar-ly':
      return import('dayjs/locale/ar-ly')
    case 'ar-ma':
      return import('dayjs/locale/ar-ma')
    case 'ar-sa':
      return import('dayjs/locale/ar-sa')
    case 'ar-tn':
      return import('dayjs/locale/ar-tn')
    case 'ar':
      return import('dayjs/locale/ar')
    case 'az':
      return import('dayjs/locale/az')
    case 'be':
      return import('dayjs/locale/be')
    case 'bg':
      return import('dayjs/locale/bg')
    case 'bm':
      return import('dayjs/locale/bm')
    case 'bi':
      return import('dayjs/locale/bi')
    case 'bn-bd':
      return import('dayjs/locale/bn-bd')
    case 'bn':
      return import('dayjs/locale/bn')
    case 'bo':
      return import('dayjs/locale/bo')
    case 'br':
      return import('dayjs/locale/br')
    case 'bs':
      return import('dayjs/locale/bs')
    case 'ca':
      return import('dayjs/locale/ca')
    case 'cs':
      return import('dayjs/locale/cs')
    case 'cv':
      return import('dayjs/locale/cv')
    case 'cy':
      return import('dayjs/locale/cy')
    case 'da':
      return import('dayjs/locale/da')
    case 'de-at':
      return import('dayjs/locale/de-at')
    case 'de-ch':
      return import('dayjs/locale/de-ch')
    case 'de':
      return import('dayjs/locale/de')
    case 'dv':
      return import('dayjs/locale/dv')
    case 'el':
      return import('dayjs/locale/el')
    case 'en-au':
      return import('dayjs/locale/en-au')
    case 'en-ca':
      return import('dayjs/locale/en-ca')
    case 'en-gb':
      return import('dayjs/locale/en-gb')
    case 'en-ie':
      return import('dayjs/locale/en-ie')
    case 'en-in':
      return import('dayjs/locale/en-in')
    case 'en-nz':
      return import('dayjs/locale/en-nz')
    case 'en-sg':
      return import('dayjs/locale/en-sg')
    case 'en-tt':
      return import('dayjs/locale/en-tt')
    case 'eo':
      return import('dayjs/locale/eo')
    case 'es-do':
      return import('dayjs/locale/es-do')
    case 'es-mx':
      return import('dayjs/locale/es-mx')
    case 'es':
      return import('dayjs/locale/es')
    case 'et':
      return import('dayjs/locale/et')
    case 'eu':
      return import('dayjs/locale/eu')
    case 'fa':
      return import('dayjs/locale/fa')
    case 'fi':
      return import('dayjs/locale/fi')
    case 'fr-ca':
      return import('dayjs/locale/fr-ca')
    case 'fr-ch':
      return import('dayjs/locale/fr-ch')
    case 'fr':
      return import('dayjs/locale/fr')
    case 'fy':
      return import('dayjs/locale/fy')
    case 'ga':
      return import('dayjs/locale/ga')
    case 'gd':
      return import('dayjs/locale/gd')
    case 'gl':
      return import('dayjs/locale/gl')
    case 'gom-latn':
      return import('dayjs/locale/gom-latn')
    case 'gu':
      return import('dayjs/locale/gu')
    case 'he':
      return import('dayjs/locale/he')
    case 'hi':
      return import('dayjs/locale/hi')
    case 'hr':
      return import('dayjs/locale/hr')
    case 'ht':
      return import('dayjs/locale/ht')
    case 'hu':
      return import('dayjs/locale/hu')
    case 'hy-am':
      return import('dayjs/locale/hy-am')
    case 'id':
      return import('dayjs/locale/id')
    case 'it-ch':
      return import('dayjs/locale/it-ch')
    case 'is':
      return import('dayjs/locale/is')
    case 'ja':
      return import('dayjs/locale/ja')
    case 'it':
      return import('dayjs/locale/it')
    case 'ka':
      return import('dayjs/locale/ka')
    case 'jv':
      return import('dayjs/locale/jv')
    case 'kk':
      return import('dayjs/locale/kk')
    case 'km':
      return import('dayjs/locale/km')
    case 'ko':
      return import('dayjs/locale/ko')
    case 'kn':
      return import('dayjs/locale/kn')
    case 'ku':
      return import('dayjs/locale/ku')
    case 'ky':
      return import('dayjs/locale/ky')
    case 'lb':
      return import('dayjs/locale/lb')
    case 'lo':
      return import('dayjs/locale/lo')
    case 'lv':
      return import('dayjs/locale/lv')
    case 'lt':
      return import('dayjs/locale/lt')
    case 'me':
      return import('dayjs/locale/me')
    case 'mi':
      return import('dayjs/locale/mi')
    case 'mk':
      return import('dayjs/locale/mk')
    case 'ml':
      return import('dayjs/locale/ml')
    case 'mn':
      return import('dayjs/locale/mn')
    case 'mr':
      return import('dayjs/locale/mr')
    case 'ms-my':
      return import('dayjs/locale/ms-my')
    case 'ms':
      return import('dayjs/locale/ms')
    case 'mt':
      return import('dayjs/locale/mt')
    case 'my':
      return import('dayjs/locale/my')
    case 'nb':
      return import('dayjs/locale/nb')
    case 'ne':
      return import('dayjs/locale/ne')
    case 'nl-be':
      return import('dayjs/locale/nl-be')
    case 'nn':
      return import('dayjs/locale/nn')
    case 'nl':
      return import('dayjs/locale/nl')
    case 'pa-in':
      return import('dayjs/locale/pa-in')
    case 'oc-lnc':
      return import('dayjs/locale/oc-lnc')
    case 'pt-br':
      return import('dayjs/locale/pt-br')
    case 'pl':
      return import('dayjs/locale/pl')
    case 'pt':
      return import('dayjs/locale/pt')
    case 'rn':
      return import('dayjs/locale/rn')
    case 'ro':
      return import('dayjs/locale/ro')
    case 'ru':
      return import('dayjs/locale/ru')
    case 'es-us':
      return import('dayjs/locale/es-us')
    case 'es-pr':
      return import('dayjs/locale/es-pr')
    case 'sd':
      return import('dayjs/locale/sd')
    case 'si':
      return import('dayjs/locale/si')
    case 'sk':
      return import('dayjs/locale/sk')
    case 'sl':
      return import('dayjs/locale/sl')
    case 'sq':
      return import('dayjs/locale/sq')
    case 'sr-cyrl':
      return import('dayjs/locale/sr-cyrl')
    case 'sr':
      return import('dayjs/locale/sr')
    case 'ss':
      return import('dayjs/locale/ss')
    case 'sv-fi':
      return import('dayjs/locale/sv-fi')
    case 'sv':
      return import('dayjs/locale/sv')
    case 'sw':
      return import('dayjs/locale/sw')
    case 'te':
      return import('dayjs/locale/te')
    case 'ta':
      return import('dayjs/locale/ta')
    case 'tet':
      return import('dayjs/locale/tet')
    case 'tg':
      return import('dayjs/locale/tg')
    case 'tk':
      return import('dayjs/locale/tk')
    case 'tl-ph':
      return import('dayjs/locale/tl-ph')
    case 'tlh':
      return import('dayjs/locale/tlh')
    case 'th':
      return import('dayjs/locale/th')
    case 'tr':
      return import('dayjs/locale/tr')
    case 'tzl':
      return import('dayjs/locale/tzl')
    case 'tzm-latn':
      return import('dayjs/locale/tzm-latn')
    case 'tzm':
      return import('dayjs/locale/tzm')
    case 'ug-cn':
      return import('dayjs/locale/ug-cn')
    case 'uk':
      return import('dayjs/locale/uk')
    case 'ur':
      return import('dayjs/locale/ur')
    case 'uz':
      return import('dayjs/locale/uz')
    case 'uz-latn':
      return import('dayjs/locale/uz-latn')
    case 'vi':
      return import('dayjs/locale/vi')
    case 'yo':
      return import('dayjs/locale/yo')
    case 'zh-cn':
      return import('dayjs/locale/zh-cn')
    case 'zh-hk':
      return import('dayjs/locale/zh-hk')
    case 'zh-tw':
      return import('dayjs/locale/zh-tw')
    case 'zh':
      return import('dayjs/locale/zh')
    case 'rw':
      return import('dayjs/locale/rw')
    case 'se':
      return import('dayjs/locale/se')
    case 'en-us':
    case 'en':
      return Promise.resolve({ name: 'en' })
    default:
      error(`Failed to match any locale for dates - locale ${localeString}`)
      return Promise.resolve({ name: 'en' })
  }
}
