import { useEffect, useRef } from 'react'

/**
 * Inspired by https://github.com/facebook/react/issues/14099#issuecomment-440013892
 * See RFC in https://github.com/reactjs/rfcs/pull/220
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useEventCallback<Fn extends (...args: any[]) => any = (...args: unknown[]) => unknown>(
  fn: Fn
): Fn
function useEventCallback<Args extends unknown[], Return>(
  fn: (...args: Args) => Return
): (...args: Args) => Return
function useEventCallback<Args extends unknown[], Return>(
  fn: (...args: Args) => Return
): (...args: Args) => Return {
  const ref = useRef(fn)
  useEffect(() => {
    ref.current = fn
  })
  return useRef(
    (...args: Args) =>
      // @ts-expect-error hide `this`
      (0, ref.current!)(...args) // NOSONAR
  ).current
}

export default useEventCallback
