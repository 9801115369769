import { type ReactNode } from 'react'
import { useIsFetching } from '@tanstack/react-query'
import clsx from 'clsx'
import { useTranslate } from '@yms/common/hooks/useTranslate'
import { OfflineBanner } from '@yms/common/components/Offline/OfflineBanner'
import { Badge } from '@yms/hexagon'

import { detectChromebug } from '@mobile/core/detectChromebug'

import { Menu } from '../Layout/Menu'
import styles from './MobileLayout.module.css'
import { MobileLogo } from './MobileLogo'

type LayoutProps = {
  readonly children?: ReactNode
  readonly menu?: ReactNode
}

export function MobileLayout({ children, menu }: LayoutProps) {
  const t = useTranslate()
  const fetchingCount = useIsFetching()

  const defaultMenu = (
    <Menu>
      <div className={clsx(styles.logo, styles.disabled)}>
        <Badge value={fetchingCount > 0 ? undefined : 0}>
          <MobileLogo />
        </Badge>
      </div>
      <Menu.Item path="/tasks" icon="swap-horizontal" title={t('tasks')} />
      <Menu.Item path="/shipping-units" icon="train-car-container" title={t('shippingUnits')} />
      <Menu.Item path="/yard" icon="map-marker-multiple" title={t('yard')} />
      <Menu.Item path="/more" icon="dots-vertical" title={t('more')} />
    </Menu>
  )

  return (
    <div className={clsx(styles.layoutContainer, { [styles.chromebug]: detectChromebug() })}>
      <div className={styles.outletWrapper}>
        <OfflineBanner />
        {children}
      </div>
      {menu ?? defaultMenu}
    </div>
  )
}
