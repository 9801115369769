import { redirect } from 'react-router-dom'

import { userManager } from '@mobile/auth/userManager'

export const authGuard = async () => {
  const user = await userManager.getUser()

  if (!user || user.expired) {
    return redirect('/auth')
  }
}
