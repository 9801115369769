import { useSuspenseQuery } from '@tanstack/react-query'
import { type YardOrderProjection } from '@yms/api/odata-client/ItgPltYmsApiModel'

import { client } from '@mobile/api/configuration'

export const shippingUnitDetails = {
  queryKey: (id: string) => ['yard-order', { entity: id }],
  queryFn: async ({ signal, id }: { signal?: AbortSignal; id: string }) => {
    const response = await client.request<YardOrderProjection>({
      path: `/indexes/yard-orders/docs('${id}')`,
      secure: true,
      query: {
        'api-version': '2023-10-01-Preview'
      },
      format: 'json',
      signal
    })

    return response.data
  }
}

export const useShippingUnitDetailsQuery = (id: string) =>
  useSuspenseQuery({
    queryKey: shippingUnitDetails.queryKey(id),
    queryFn: ({ signal }) => shippingUnitDetails.queryFn({ signal, id })
  })
