import { isString } from 'lodash-es'
import { HttpClient } from '@yms/api/client/v1/http-client'
import { attachClient } from '@yms/common/utils/attachClient'
import { YMS_SOURCE_SYSTEM } from '@yms/common/constants'

import { API_BASE_URL, API_SUBSCRIPTION_KEY } from '@mobile/App.env'
import { userManager } from '@mobile/auth/userManager'

export const client = new HttpClient({
  baseUrl: API_BASE_URL,
  customFetch: async (...fetchParams) => {
    const isApimUrl = isString(fetchParams[0]) && fetchParams[0].includes('v1/api')
    if (isApimUrl) {
      fetchParams[0] = (fetchParams[0] as string).replace('v1/api/v1', 'v1/api')
    }

    const response = await fetch(...fetchParams)
    if (response.status === 401) {
      const user = await userManager.getUser()

      if (user?.expired) {
        window.location.href = `/auth/failed?error="Could not authenticate. Please try again."`
      }
    }

    return response
  },
  async securityWorker(token) {
    if (!token || typeof token !== 'string') return

    return {
      format: 'json',
      headers: {
        Authorization: `Bearer ${token}`,
        'Ocp-Apim-Subscription-Key': API_SUBSCRIPTION_KEY,
        'X-Request-Initiator': YMS_SOURCE_SYSTEM
      }
    }
  }
})

attachClient(client)
