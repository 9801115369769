/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GetOperationalBlocksODataParams,
  HttpValidationProblemDetails,
  ODataOperationalBlockDtoArrayODataCollectionResponse
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class OperationalBlocks<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * No description
   *
   * @tags OperationalBlocks
   * @name GetOperationalBlocksOData
   * @request GET:/api/v1/operational-blocks
   * @secure
   */
  getOperationalBlocksOData = (
    query: GetOperationalBlocksODataParams,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ODataOperationalBlockDtoArrayODataCollectionResponse,
      HttpValidationProblemDetails
    >({
      path: `/api/v1/operational-blocks`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
}
