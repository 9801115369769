import { type YardTaskProjection } from '@yms/api/odata-client/ItgPltYmsApiModel'

import { type TablePrefetchConfig } from '@mobile/prefetch/PrefetchConfig'
import { prefetch } from '@mobile/prefetch'

import { yardTaskDetails } from '../../common/useYardTaskDetailsQuery'
import { prefetchLocation } from '../../common/components/LocationDrawer/LocationDrawer.prefetch'
import { fetcher } from './Tasks.fetcher'
import { TABLE_NAME } from './Tasks.config'

export const prefetchConfig: TablePrefetchConfig = {
  type: 'table',
  name: TABLE_NAME,
  fetcher,
  detailsFetcher: (queryClient, row: YardTaskProjection) => {
    const queryKey = yardTaskDetails.queryKey(row.id)
    prefetch.queryKeys.add(JSON.stringify(queryKey))
    queryClient.setQueryData(queryKey, row)
    return prefetchLocation(queryClient, row)
  }
}
