import { isNumber, isString } from 'lodash-es'
import { type AsyncStorage } from 'oidc-client-ts'
import { keys, get, set, del, clear, createStore, type UseStore } from 'idb-keyval'

export class IdbAsyncStorage implements AsyncStorage {
  private store: UseStore

  constructor(dbName: string = 'oidc-client', storeName: string = 'oidc-client') {
    this.store = createStore(dbName, storeName)
  }

  get length() {
    return keys(this.store).then(k => k.length)
  }

  async getItem(key: string): Promise<string | null> {
    return (await get(key, this.store)) ?? null
  }

  async key(index: number): Promise<string | null> {
    const result = (await keys(this.store)).at(index)

    if (isString(result) || isNumber(result)) {
      return `${result}`
    }

    return null
  }

  async removeItem(key: string): Promise<void> {
    return del(key, this.store)
  }

  setItem(key: string, value: string): Promise<void> {
    return set(key, value, this.store)
  }

  clear(): Promise<void> {
    return clear(this.store)
  }
}
