import { redirect } from 'react-router-dom'
import { getMany } from 'idb-keyval'

export const contextGuard = async () => {
  const [handlerId, handlerType, yardZoneIds] = await getMany<string>([
    'handlerId',
    'handlerType',
    'yardZoneIds'
  ])

  if (!handlerId || !handlerType || !yardZoneIds || yardZoneIds.length === 0) {
    return redirect('/login/handler-selection')
  }
}
