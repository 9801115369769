import { type ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'

let loggerInstance: ApplicationInsights | undefined
export const attachLogger = (logger: ApplicationInsights) => {
  loggerInstance = logger
}

export const info = (message: string) => {
  const obj = {
    message,
    severityLevel: SeverityLevel.Information
  }

  if (loggerInstance) {
    loggerInstance.trackTrace(obj)
  }
  console.debug('[info]:', obj)
}

export const warning = (message: string) => {
  const obj = {
    message,
    severityLevel: SeverityLevel.Warning
  }

  if (loggerInstance) {
    loggerInstance.trackTrace(obj)
  }
  console.warn('[warning]:', obj)
}

export const error = (message: string, exception?: Error) => {
  const obj = {
    message,
    exception,
    severityLevel: SeverityLevel.Error
  }

  if (loggerInstance) {
    loggerInstance.trackException(obj)
  }
  console.error('[error]:', obj)
}

export const event = (name: string, properties: Record<string, unknown>) => {
  const obj = {
    name,
    properties
  }

  if (loggerInstance) {
    loggerInstance.trackEvent(obj)
  }

  console.debug('[event]', obj)
}
