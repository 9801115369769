import { type RouteObject } from 'react-router-dom'

export const mainModuleRouter: RouteObject[] = [
  {
    path: 'tasks',
    lazy: () => import('./pages/tasks')
  },
  {
    path: 'tasks/:id',
    lazy: () => import('./pages/tasks-details')
  },
  {
    path: 'shipping-units',
    lazy: () => import('./pages/shipping-units')
  },
  {
    path: 'shipping-units/:id',
    lazy: () => import('./pages/shipping-units-details')
  },
  {
    path: 'yard',
    lazy: () => import('./pages/yard')
  }
]
