import { useState, type ReactNode, useEffect } from 'react'
import clsx from 'clsx'
import { Text, Portal } from '@yms/hexagon'

import { useTranslate } from '../../hooks/useTranslate'
import styles from './LoadingOverlay.module.css'

type LoadingOverlayProps = {
  readonly relative?: boolean
  readonly visible?: boolean
  readonly label?: string
  readonly inline?: boolean
}

function MaybePortal({
  withoutPortal,
  children
}: {
  readonly withoutPortal?: boolean
  readonly children: ReactNode
}) {
  if (withoutPortal) return children
  return <Portal container={() => document.getElementById('modal-root')}>{children}</Portal>
}

const delayedTexts = ['takingLongerThanExpected', 'internetConnectionPoor']

export function LoadingOverlay({ relative, inline, visible, label }: LoadingOverlayProps) {
  const t = useTranslate()
  const [delayed, setDelayed] = useState(0)

  useEffect(() => {
    if (visible) {
      const interval = setInterval(() => {
        setDelayed(d => (d + 1) % (delayedTexts.length + 1))
      }, 8_000)

      return () => {
        clearInterval(interval)
      }
    } else {
      setDelayed(0)
    }
  }, [visible])

  return (
    <MaybePortal withoutPortal={relative || inline}>
      <div
        className={clsx(styles.container, {
          [styles.relative]: relative,
          [styles.visible]: visible,
          [styles.inline]: inline
        })}
      >
        <div className={styles.wrapper}>
          <hx-spinner size="lg" color="brand"></hx-spinner>
          <Text bold>{t(delayed > 0 ? delayedTexts[delayed - 1] : (label ?? 'pleaseWait'))}</Text>
        </div>
      </div>
    </MaybePortal>
  )
}
