import { type Dispatch, type SetStateAction } from 'react'
import { produce } from 'immer'
import {
  type CheckboxFiltering,
  type FilteringStatus
} from '@yms/common/components/Table/Table.filtering.types'
import { useTranslate } from '@yms/hexagon/hooks/useTranslate'
import { SelectionBox } from '@yms/hexagon/SelectionBox'

export const CheckboxRenderer = ({
  name,
  options,
  currentFiltering,
  setCurrentFiltering
}: {
  name: string
  options: CheckboxFiltering['options']
  readonly currentFiltering: FilteringStatus
  readonly setCurrentFiltering: Dispatch<SetStateAction<FilteringStatus>>
}) => {
  const t = useTranslate()

  return options.map(option => {
    const checked = currentFiltering[name]?.includes(option.value)

    return (
      <SelectionBox
        key={`${option.label}-${option.value}-${option.labelProps}`}
        data-testid={option.label}
        name={option.label}
        label={t(option.label, option.labelProps)}
        value={option.value}
        checked={checked}
        onChange={(value, selected) => {
          setCurrentFiltering(
            produce(draft => {
              if (!draft[name]) {
                draft[name] = []
              }

              if (selected) {
                draft[name]?.push(value)
              } else {
                const index = draft[name]?.findIndex(v => v === value)
                if (typeof index !== 'undefined') {
                  draft[name]?.splice(index, 1)
                }
              }

              if (draft[name]?.length === 0) {
                delete draft[name]
              }
            })
          )
        }}
      />
    )
  })
}
