import { type HttpClient } from '@yms/api/client'

let httpClient: HttpClient<unknown> | undefined
export const attachClient = (client: HttpClient<unknown>) => {
  console.info('Client attached.')
  httpClient = client
}

export const getClient = () => {
  if (!httpClient) {
    console.info('Client not attached.')
    throw new Error('Client not attached.')
  }

  return httpClient
}
