import { type DynamicFilteringRenderProps } from '@yms/common/components/Table/Table.filtering.types'
import { Button } from '@yms/hexagon'
import { useTranslate } from '@yms/hexagon/hooks/useTranslate'

import { CheckboxRenderer } from '@mobile/core/Hexagon/Table/FilteringDrawer/components/CheckboxRenderer'

import classes from './FinishedBy.module.css'
import { useYardQuery } from './useYardQuery'

export function Yard({
  open,
  name,
  currentState,
  setCurrentState
}: Readonly<DynamicFilteringRenderProps>) {
  const t = useTranslate()

  const { visibleSlice, total, canShowMore, showMore } = useYardQuery({ open })

  return (
    <div className={classes.Wrapper}>
      <div className={classes.Flex}>
        <CheckboxRenderer
          name={name}
          options={visibleSlice}
          currentFiltering={currentState}
          setCurrentFiltering={setCurrentState}
        />
      </div>
      <div>
        {total > 3 && (
          <Button data-testid="show-more" disabled={!canShowMore()} onClick={() => showMore()}>
            {t('showMore')}
          </Button>
        )}
      </div>
    </div>
  )
}
