import type HxIcon from '@katoennatie/hexagon/icon'

export type IconProps = {
  readonly name: string
  readonly color?: HxIcon['color']
  readonly size?: HxIcon['size']
  readonly slot?: HxIcon['slot']
}

export function Icon({ name, color, size, slot }: IconProps) {
  return <hx-icon name={name} color={color} size={size} slot={slot}></hx-icon>
}
