import { useEffect } from 'react'
import { set, del } from 'idb-keyval'
import { getIsOffline } from '@yms/common/components/Offline/getIsOffline'
import { snackbar } from '@yms/common/utils/snackbar'

import { API_BASE_URL, API_SUBSCRIPTION_KEY, VAPID_KEY } from '@mobile/App.env'
import { userManager } from '@mobile/auth/userManager'
import { notificationApi } from '@mobile/api/notification'
import { prefetch } from '@mobile/prefetch'

import { requestPermission } from './requestPermission'

const registerSubscription = async () => {
  const permission = await requestPermission()

  if (permission === 'granted') {
    const registration = await navigator.serviceWorker.ready
    let subscription = await registration.pushManager.getSubscription()

    if (!subscription) {
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: VAPID_KEY
      })
    }

    const subscriptionInfo = subscription.toJSON()
    const response = await notificationApi.subscribe({
      endpoint: subscriptionInfo.endpoint!,
      auth: subscriptionInfo.keys?.auth as string,
      p256DH: subscriptionInfo.keys?.p256dh as string
    })
    const subscriptionId = response.data

    await set('subscriptionId', subscriptionId)
    return subscriptionId
  } else {
    await del('subscriptionId')
    snackbar.error('Application needs browser permission! Allow notifications and refresh.')
    return undefined
  }
}

export const useNotificationRegistration = () => {
  useEffect(() => {
    let subscriptionId: string | undefined

    async function onSubscription() {
      if (getIsOffline()) {
        return
      }

      if (subscriptionId && document.visibilityState === 'hidden') {
        const user = await userManager.getUser()

        if (!user) return

        fetch(
          new Request(
            `${API_BASE_URL}/api/push-notifications/subscriptions/${subscriptionId}/cancel`,
            {
              method: 'POST',
              keepalive: true,
              headers: {
                Authorization: `Bearer ${user.access_token}`,
                'Ocp-Apim-Subscription-Key': API_SUBSCRIPTION_KEY
              }
            }
          )
        )
        subscriptionId = undefined
        await del('subscriptionId')
      } else if (!subscriptionId) {
        subscriptionId = await registerSubscription()
        subscriptionId && prefetch.stopRefetch()
      }
    }

    onSubscription()

    document.addEventListener('visibilitychange', onSubscription) // NOSONAR

    return () => {
      document.removeEventListener('visibilitychange', onSubscription) // NOSONAR
    }
  }, [])
}
