import { useSuspenseQuery } from '@tanstack/react-query'
import { type QFilterExpression, createQueryBuilderV4 } from '@odata2ts/odata-query-builder'
import { QODataOperationalYardZoneDto } from '@yms/api/odata-client/QItgPltYmsApi'
import { odataStringToRecord } from '@yms/api/odataStringToRecord'
import { OperationalYardZones } from '@yms/api/client'

import { type LocationPayload } from '../../useYardLocation'
import { getClient } from '../../../../utils/attachClient'

const operationalYardZonesApi = new OperationalYardZones(getClient())

export const yardZone = {
  queryKey: ({ site, yardZoneIds }: { site?: string; yardZoneIds: string[] | null }) => [
    'yard-zones',
    { entity: site, context: { yardZoneIds } }
  ],
  queryFn: async ({
    signal,
    site,
    yardZoneIds
  }: {
    signal: AbortSignal
    site?: string
    yardZoneIds: string[] | null
  }) => {
    if (!site) throw new Error('Missing site')

    const dto = new QODataOperationalYardZoneDto()
    const builder = createQueryBuilderV4('b', dto)

    let yzFilter: QFilterExpression | null = null
    if (yardZoneIds) {
      for (const yardZoneId of yardZoneIds) {
        if (!yzFilter) {
          yzFilter = dto.id.eq(yardZoneId)
        } else {
          yzFilter = yzFilter.or(dto.id.eq(yardZoneId))
        }
      }
    }

    const query = builder
      .filter(dto.isActive.eq(true), dto.siteCode.eq(site), yzFilter)
      .orderBy(dto.name.asc())
      .build()
    const odata = odataStringToRecord(query)

    return operationalYardZonesApi
      .getOperationalYardZonesOData(odata, { signal })
      .then(r => r.data.value ?? [])
  }
}

export const useYardZoneQuery = ({
  currentYardZoneIds,
  location,
  plannedYardZoneId
}: {
  readonly currentYardZoneIds: string[] | null
  readonly location: LocationPayload | undefined
  readonly plannedYardZoneId?: string
}) => {
  const site = location?.site

  const yardZoneIds = plannedYardZoneId
    ? [...(currentYardZoneIds ?? []), plannedYardZoneId]
    : currentYardZoneIds

  return useSuspenseQuery({
    queryKey: yardZone.queryKey({
      site,
      yardZoneIds
    }),
    queryFn: ({ signal }) =>
      yardZone.queryFn({
        signal,
        yardZoneIds,
        site
      })
  })
}
