import { useEffect } from 'react'
import useEventCallback from '@yms/common/components/ClickAwayListener/useEventCallback'

import { registerAction } from './registerAction'

export const useAction = <T>(name: string, handler: (payload: T) => void | Promise<unknown>) => {
  const eventHandler = useEventCallback(handler)

  useEffect(() => {
    return registerAction(name, eventHandler)
  }, [name, eventHandler])
}
