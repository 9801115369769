import { useState } from 'react'
import LockAlertIcon from 'mdi-material-ui/LockAlert'
import clsx from 'clsx'
import { Button, Text } from '@yms/hexagon'
import { OfflineBanner } from '@yms/common/components/Offline/OfflineBanner'
import { useIsOffline } from '@yms/common/components/Offline/useIsOffline'
import { useTranslate } from '@yms/common/hooks/useTranslate'

import { detectChromebug } from '@mobile/core/detectChromebug'
import { userManager } from '@mobile/auth/userManager'

import styles from './auth.module.css'

export function Login() {
  const t = useTranslate()
  const isOffline = useIsOffline()
  const [loading, setLoading] = useState(false)

  return (
    <>
      <OfflineBanner fixed />
      <div className={clsx(styles.center, { [styles.chromebug]: detectChromebug() })}>
        <div className={styles.container}>
          <LockAlertIcon className={styles.icon} />
          <Text class={styles.text} color="base" variant="action">
            {t('loginRequired')}
          </Text>
          <Button
            fullWidth
            size="lg"
            disabled={isOffline}
            variant="primary"
            className={styles.button}
            data-testid="login-button"
            loading={loading}
            onClick={() => {
              setLoading(true)
              userManager.signinRedirect().finally(() => setLoading(false))
            }}
          >
            {t('login')}
          </Button>
        </div>
      </div>
    </>
  )
}
