import { type ReactNode, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { LoadingOverlay } from '@yms/common/components/LoadingOverlay'
import { PushNotificationType } from '@yms/api/client'

import { useNotificationRegistration } from '@mobile/notifications/useNotificationRegistration'
import { useNotificationHandler } from '@mobile/notifications/notificationHandler'
import { yardTaskDetails } from '@mobile/modules/main/common/useYardTaskDetailsQuery'
import { shippingUnitDetails } from '@mobile/modules/main/pages/shipping-units-details/useShippingUnitDetailsQuery'

import { MobileLayout } from '../MobileLayout'

type NotificationData = { entityIds: string[] }

type LayoutProps = {
  readonly children?: ReactNode
}

export function Layout({ children }: LayoutProps) {
  const queryClient = useQueryClient()
  useNotificationRegistration()

  useNotificationHandler(({ notificationType, data }) => {
    switch (notificationType) {
      case PushNotificationType.YardTaskChanged: {
        queryClient.invalidateQueries({
          queryKey: ['tasks', 'table']
        })
        queryClient.invalidateQueries({
          queryKey: ['yard-tasks']
        })
        queryClient.invalidateQueries({
          queryKey: ['shifts', 'table']
        })
        for (const taskId of (data as NotificationData).entityIds) {
          queryClient.invalidateQueries({
            queryKey: yardTaskDetails.queryKey(taskId)
          })
        }
        break
      }
      case PushNotificationType.YardOrderChanged: {
        queryClient.invalidateQueries({
          queryKey: ['shipping-units']
        })
        for (const id of (data as NotificationData).entityIds) {
          queryClient.invalidateQueries({
            queryKey: shippingUnitDetails.queryKey(id)
          })
        }
        break
      }
      case PushNotificationType.GenericNotification: {
        console.info(`Generic "${notificationType}" notification type received`)
        break
      }
      default: {
        console.error(`Unknown "${notificationType}" notification type received`)
      }
    }
  }, [])

  return (
    <Suspense fallback={<LoadingOverlay visible />}>
      <MobileLayout>
        <Outlet />
        {children}
      </MobileLayout>
    </Suspense>
  )
}
