import { useSuspenseQuery } from '@tanstack/react-query'

import { operatorsApi } from '@mobile/api/operators'

export const operatorDetails = {
  queryKey: (operatorId: string) => [{ entity: 'operator', operatorId }],
  queryFn: async (operatorId: string, signal: AbortSignal) => {
    const response = await operatorsApi.getOperatorById(operatorId, { signal })
    return response.data
  }
}

export const useOperatorDetailsQuery = (operatorId: string) => {
  return useSuspenseQuery({
    queryKey: operatorDetails.queryKey(operatorId),
    queryFn: ({ signal }) => operatorDetails.queryFn(operatorId, signal)
  })
}
