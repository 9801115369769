import { useEffect, useState, type ReactNode } from 'react'
import { useIsRestoring } from '@tanstack/react-query'
import { LoadingOverlay } from '@yms/common/components/LoadingOverlay'

export function PersistGate({ children }: { readonly children: ReactNode | ReactNode[] }) {
  const isRestoring = useIsRestoring()
  const [mounted, setMounted] = useState(isRestoring)

  useEffect(() => {
    if (isRestoring) {
      setMounted(true)
    } else {
      setTimeout(() => {
        setMounted(false)
      }, 350)
    }
  }, [isRestoring])

  return (
    <>
      {mounted && <LoadingOverlay visible={isRestoring} />}
      {!isRestoring && children}
    </>
  )
}
