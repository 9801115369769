import { get, isString } from 'lodash-es'
import type HxStatusChip from '@katoennatie/hexagon/status-chip'
import { type CellContext } from '@tanstack/react-table'
import { useTranslate } from '@yms/common/hooks/useTranslate'

export function ChipCell<TData, TValue>(info: Readonly<CellContext<TData, TValue>>) {
  const t = useTranslate()
  const value = info.getValue()

  const mapping = info.column.columnDef.meta?.chipMapping

  if (isString(value)) {
    return (
      <hx-status-chip variant={get(mapping, value, 'info') as HxStatusChip['variant']}>
        {t(value)}
      </hx-status-chip>
    )
  }

  return null
}
