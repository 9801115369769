import { currentOperator } from '@mobile/auth/hooks/useCurrentOperatorQuery'
import { type QueryPrefetchConfig } from '@mobile/prefetch/PrefetchConfig'

import { operatorDetails } from './useOperatorQuery'

export const prefetchConfig: QueryPrefetchConfig = {
  type: 'query',
  async query(queryClient) {
    const operator = await queryClient.ensureQueryData({
      queryKey: currentOperator.queryKey,
      queryFn: currentOperator.queryFn
    })

    const { operatorId } = operator

    await queryClient.ensureQueryData({
      queryKey: operatorDetails.queryKey(operatorId),
      queryFn: ({ signal }) => operatorDetails.queryFn(operatorId, signal)
    })
  }
}
