/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BlockDetailsDto,
  BlockDto,
  ClearDefaultSlotPayload,
  CreateContainerStackBlockPayload,
  CreateFillingPipeBlockPayload,
  CreateGateBlockPayload,
  CreateParkingBlockPayload,
  CreateSiloBlockPayload,
  GetODataBlocksParams,
  HttpValidationProblemDetails,
  ODataBlockDtoArrayODataCollectionResponse,
  ProblemDetails,
  SetDefaultSlotPayload,
  UpdateContainerStackBlockPayload,
  UpdateFillingPipeBlockPayload,
  UpdateGateBlockPayload,
  UpdateParkingBlockPayload,
  UpdateSiloBlockPayload
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Blocks<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * No description
   *
   * @tags Blocks
   * @name UpdateContainerStackBlock
   * @request PUT:/api/v1/blocks/{id}/container-stacks
   * @secure
   */
  updateContainerStackBlock = (
    id: string,
    data: UpdateContainerStackBlockPayload,
    params: RequestParams = {}
  ) =>
    this.http.request<
      BlockDto,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/blocks/${id}/container-stacks`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name UpdateSiloBlock
   * @request PUT:/api/v1/blocks/{id}/silos
   * @secure
   */
  updateSiloBlock = (id: string, data: UpdateSiloBlockPayload, params: RequestParams = {}) =>
    this.http.request<
      BlockDto,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/blocks/${id}/silos`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name UpdateGateBlock
   * @request PUT:/api/v1/blocks/{id}/gates
   * @secure
   */
  updateGateBlock = (id: string, data: UpdateGateBlockPayload, params: RequestParams = {}) =>
    this.http.request<
      BlockDto,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/blocks/${id}/gates`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name UpdateParkingBlock
   * @request PUT:/api/v1/blocks/{id}/parkings
   * @secure
   */
  updateParkingBlock = (id: string, data: UpdateParkingBlockPayload, params: RequestParams = {}) =>
    this.http.request<
      BlockDto,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/blocks/${id}/parkings`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name UpdateFillingPipeBlock
   * @request PUT:/api/v1/blocks/{id}/filling-pipes
   * @secure
   */
  updateFillingPipeBlock = (
    id: string,
    data: UpdateFillingPipeBlockPayload,
    params: RequestParams = {}
  ) =>
    this.http.request<
      BlockDto,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/blocks/${id}/filling-pipes`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name SetDefaultSlot
   * @request PATCH:/api/v1/blocks/{id}/default-slot/mark
   * @secure
   */
  setDefaultSlot = (id: string, data: SetDefaultSlotPayload, params: RequestParams = {}) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/blocks/${id}/default-slot/mark`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name ClearDefaultSlot
   * @request PATCH:/api/v1/blocks/{id}/default-slot/unmark
   * @secure
   */
  clearDefaultSlot = (id: string, data: ClearDefaultSlotPayload, params: RequestParams = {}) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/blocks/${id}/default-slot/unmark`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name GetById
   * @request GET:/api/v1/blocks/{id}
   * @secure
   */
  getById = (id: string, params: RequestParams = {}) =>
    this.http.request<
      BlockDetailsDto,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/blocks/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name GetODataBlocks
   * @request GET:/api/v1/blocks
   * @secure
   */
  getODataBlocks = (query: GetODataBlocksParams, params: RequestParams = {}) =>
    this.http.request<ODataBlockDtoArrayODataCollectionResponse, HttpValidationProblemDetails>({
      path: `/api/v1/blocks`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name DeactivateBlock
   * @request PATCH:/api/v1/blocks/{id}/deactivate
   * @secure
   */
  deactivateBlock = (id: string, params: RequestParams = {}) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/blocks/${id}/deactivate`,
      method: 'PATCH',
      secure: true,
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name CreateContainerStackBlock
   * @summary Create a new container stack.
   * @request POST:/api/v1/blocks/container-stacks
   * @secure
   */
  createContainerStackBlock = (
    data: CreateContainerStackBlockPayload,
    params: RequestParams = {}
  ) =>
    this.http.request<BlockDto, HttpValidationProblemDetails>({
      path: `/api/v1/blocks/container-stacks`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name CreateSiloBlock
   * @request POST:/api/v1/blocks/silos
   * @secure
   */
  createSiloBlock = (data: CreateSiloBlockPayload, params: RequestParams = {}) =>
    this.http.request<BlockDto, HttpValidationProblemDetails>({
      path: `/api/v1/blocks/silos`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name CreateGateBlock
   * @request POST:/api/v1/blocks/gates
   * @secure
   */
  createGateBlock = (data: CreateGateBlockPayload, params: RequestParams = {}) =>
    this.http.request<BlockDto, HttpValidationProblemDetails>({
      path: `/api/v1/blocks/gates`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name CreateParkingBlock
   * @request POST:/api/v1/blocks/parkings
   * @secure
   */
  createParkingBlock = (data: CreateParkingBlockPayload, params: RequestParams = {}) =>
    this.http.request<BlockDto, HttpValidationProblemDetails>({
      path: `/api/v1/blocks/parkings`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name CreateFillingPipeBlock
   * @request POST:/api/v1/blocks/filling-pipes
   * @secure
   */
  createFillingPipeBlock = (data: CreateFillingPipeBlockPayload, params: RequestParams = {}) =>
    this.http.request<BlockDto, HttpValidationProblemDetails>({
      path: `/api/v1/blocks/filling-pipes`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags Blocks
   * @name ActivateBlock
   * @request PATCH:/api/v1/blocks/{id}/activate
   * @secure
   */
  activateBlock = (id: string, params: RequestParams = {}) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/blocks/${id}/activate`,
      method: 'PATCH',
      secure: true,
      ...params
    })
}
