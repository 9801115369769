import { type LoaderFunctionArgs, type LoaderFunction } from 'react-router-dom'

export const loaderWithGuards = (guards: LoaderFunction[], loader?: LoaderFunction) => {
  return async (args: LoaderFunctionArgs) => {
    for (const loaderGuard of guards) {
      const result = await loaderGuard(args)
      if (result) return result
    }

    return loader?.(args) ?? null
  }
}
