import { useEffect, useState } from 'react'
import { onlineManager } from '@tanstack/react-query'

export const useIsOffline = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  useEffect(() => {
    const cleanup = onlineManager.subscribe(isOnline => {
      setIsOnline(isOnline)
    })

    return cleanup
  }, [])

  return !isOnline
}
