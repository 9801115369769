import { type RouteObject } from 'react-router-dom'

export const setupModuleRouter: RouteObject[] = [
  {
    path: 'more',
    lazy: () => import('./pages/more')
  },
  {
    path: 'more/settings',
    lazy: () => import('./pages/more-settings')
  },
  {
    path: 'more/userContext',
    lazy: () => import('./pages/more-userContext')
  },
  {
    path: 'debug',
    lazy: () => import('./pages/debug')
  }
]
