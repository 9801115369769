import {
  type ForwardedRef,
  forwardRef,
  type ReactNode,
  useEffect,
  useState,
  cloneElement,
  Fragment,
  isValidElement
} from 'react'
import { createPortal } from 'react-dom'

import useForkRef from './useForkRef'
import setRef from './setRef'

export interface PortalProps {
  /**
   * The children to render into the `container`.
   */
  readonly children?: ReactNode
  /**
   * An HTML element or function that returns one.
   * The `container` will have the portal children appended to it.
   *
   * You can also provide a callback, which is called in a React layout effect.
   * This lets you set the container from a ref, and also makes server-side rendering possible.
   *
   * By default, it uses the body of the top-level document object,
   * so it's simply `document.body` most of the time.
   */
  readonly container?: Element | (() => Element | null) | null
  /**
   * The `children` will be under the DOM hierarchy of the parent component.
   * @default false
   */
  readonly disablePortal?: boolean
}

function getContainer(container: PortalProps['container']) {
  return typeof container === 'function' ? container() : container
}

/**
 * Portals provide a first-class way to render children into a DOM node
 * that exists outside the DOM hierarchy of the parent component.
 *
 * Demos:
 *
 * - [Portal](https://mui.com/base-ui/react-portal/)
 *
 * API:
 *
 * - [Portal API](https://mui.com/base-ui/react-portal/components-api/#portal)
 */
export const Portal = forwardRef(function Portal(
  props: PortalProps,
  forwardedRef: ForwardedRef<Element>
) {
  const { children, container, disablePortal = false } = props
  const [mountNode, setMountNode] = useState<ReturnType<typeof getContainer>>(
    disablePortal ? null : getContainer(container) || document.body
  )
  const handleRef = useForkRef(
    // @ts-expect-error TODO upstream fix
    isValidElement(children) ? children.ref : null,
    forwardedRef
  )

  useEffect(() => {
    if (!disablePortal) {
      setMountNode(getContainer(container) || document.body)
    }
  }, [container, disablePortal])

  useEffect(() => {
    if (mountNode && !disablePortal) {
      setRef(forwardedRef, mountNode)
      return () => {
        setRef(forwardedRef, null)
      }
    }

    return undefined
  }, [forwardedRef, mountNode, disablePortal])

  if (disablePortal) {
    if (isValidElement(children)) {
      const newProps = {
        ref: handleRef
      }
      return cloneElement(children, newProps)
    }
    return <Fragment>{children}</Fragment>
  }

  return <Fragment>{mountNode ? createPortal(children, mountNode) : mountNode}</Fragment>
}) as React.ForwardRefExoticComponent<PortalProps & React.RefAttributes<Element>>
