import { type RouteObject } from 'react-router-dom'

export const loginModuleRouter: RouteObject[] = [
  {
    path: 'handler-selection',
    lazy: () => import('./pages/handler-selection')
  },
  {
    path: 'yard-zone-selection',
    lazy: () => import('./pages/yard-zone-selection')
  },
  {
    path: 'summary',
    lazy: () => import('./pages/summary')
  }
]
