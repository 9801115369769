import { type PushNotificationType } from '@yms/api/client'

const bc = new BroadcastChannel('global')

export type MessagePayload = {
  title?: string
  message?: string
  data: unknown
  notificationType: PushNotificationType
}

export type Callback = (payload: MessagePayload) => void

export const callbackSet = new Set<Callback>()

bc.addEventListener('message', event => {
  console.info({ event, data: event.data })

  if (event.data.type === 'NOTIFICATION_RECEIVED') {
    for (const cb of callbackSet) {
      cb(event.data)
    }
  }
})
