import { type ReactNode } from 'react'
import clsx from 'clsx'

import { MenuItem } from './Menu.item'
import styles from './Menu.module.css'

export function Menu({
  children,
  right
}: {
  readonly children?: ReactNode
  readonly right?: ReactNode
}) {
  return (
    <div className={clsx('actionbar', styles.actionbar)}>
      <nav className={styles.navigation}>{children ?? <span className={styles.span} />}</nav>
      {right && <div className={styles.alignRight}>{right}</div>}
      <div className={clsx(styles.actionbar, styles.portal)} id="menu-portal"></div>
    </div>
  )
}

Menu.Item = MenuItem
