export const requestPermission = async (): Promise<NotificationPermission> => {
  if (!('Notification' in window)) {
    // Check if the browser supports notifications
    return 'denied'
  } else if (Notification.permission === 'granted') {
    return 'granted'
  } else if (Notification.permission !== 'denied') {
    // We need to ask the user for permission
    return Notification.requestPermission()
  }

  return 'denied'
}
