// window.env imported values

import { get } from 'lodash-es'

export type Environment = 'DEV' | 'TST' | 'BUG' | 'UAT' | 'PRD'

import { type FlattenObjectKeys } from '@mobile/core/UtilityTypes/FlattenObjectKeys'

function getEnv<TExpectedType = string>(key: FlattenObjectKeys<typeof window.env>) {
  return get(window.env, key) as TExpectedType
}

export const REGION = getEnv('region')
export const ENVIRONMENT = getEnv<Environment>('environment')

export const AUTH_AUTHORITY = getEnv('auth.authority')
export const AUTH_BASE_URL = getEnv('auth.baseUrl')
export const AUTH_IDENTITY_SERVER_CLIENT_ID = getEnv('auth.idsClientId')
export const AUTH_SCOPE = getEnv('auth.scope')

export const API_BASE_URL = getEnv('api.baseUrl')
export const API_SUBSCRIPTION_KEY = getEnv('api.subscriptionKey')
export const VAPID_KEY = getEnv('api.vapidKey')

// Application Insights
export const AI_CONNECTION_STRING = getEnv('logging')
export const AI_ENABLED = !getEnv('auth.baseUrl')?.includes('localhost')
