import type HxHeading from '@katoennatie/hexagon/heading'

import { serializeForHexagon } from '../utils/serializeForHexagon'
import { useTranslate } from '../hooks/useTranslate'

export type HeadingProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly children: any
  readonly variant?: HxHeading['variant']
  readonly color?: HxHeading['color'] | 'inherit'
  readonly level?: HxHeading['level']
  readonly class?: string
}

export function Heading({ variant, level, color, class: _class, children }: HeadingProps) {
  const t = useTranslate()
  return (
    <hx-heading
      class={serializeForHexagon(_class)}
      variant={serializeForHexagon(variant)}
      level={serializeForHexagon(level)}
      // @ts-expect-error inherit should be supported
      color={serializeForHexagon(color)}
    >
      {typeof children === 'string' ? t(children) : children}
    </hx-heading>
  )
}
