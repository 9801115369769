import { type QueryClient } from '@tanstack/react-query'

import { yardZonesDetails } from '@mobile/api/yard-zones/useYardZonesDetailsQuery'

import { currentOperator } from '../hooks/useCurrentOperatorQuery'
import { currentHandlerType } from './storage'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Resolved<T extends (...args: any) => any> = Awaited<ReturnType<T>>

export const getUserContext = async (queryClient: QueryClient) => {
  try {
    const handlerType = await queryClient.fetchQuery(currentHandlerType)
    const { operatorId, yardZones, tables } = await queryClient.fetchQuery({
      queryKey: currentOperator.queryKey,
      queryFn: currentOperator.queryFn
    })
    const location = yardZonesDetails.select(yardZones)
    return { operatorId, handlerType, location, tables } as const
  } catch {
    const handlerType = queryClient.getQueryData<Resolved<typeof currentHandlerType.queryFn>>(
      currentHandlerType.queryKey
    )
    const stale = queryClient.getQueryData<Resolved<typeof currentOperator.queryFn>>(
      currentOperator.queryKey
    )

    if (!stale) return {}

    const location = yardZonesDetails.select(stale.yardZones)
    return {
      operatorId: stale.operatorId,
      handlerType: handlerType ?? null,
      location,
      tables: stale.tables
    } as const
  }
}
