import { useMemo } from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { InlineAlert } from '@yms/hexagon'

import { useTranslate } from '../../hooks/useTranslate'
import { useIsOffline } from './useIsOffline'
import styles from './OfflineBanner.module.css'

type OfflineBannerProps = {
  readonly fixed?: boolean
  readonly className?: string
}

export function OfflineBanner({ className, fixed }: OfflineBannerProps) {
  const t = useTranslate()
  const isOffline = useIsOffline()
  const offlineSince = useMemo(() => (isOffline ? dayjs() : null), [isOffline])

  if (!isOffline) return null

  return (
    <div className={clsx(styles.offlineBanner, fixed && styles.fixed, className)}>
      <InlineAlert variant="error" heading={t('offline')}>
        {t('offlineBanner:since', { since: offlineSince?.format('LT') })}
      </InlineAlert>
    </div>
  )
}
