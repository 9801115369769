import { type AzureAISearchFacet } from '@yms/api/client'

import { client } from '../configuration'

export type AzureSearchFacets = Record<string, AzureAISearchFacet[] | null>

const collator = new Intl.Collator('kn', { sensitivity: 'accent' })

export const searchInstance = async <TData>(
  index: string,
  payload: Record<string, string | number | boolean | undefined | string[]>,
  signal?: AbortSignal
) => {
  const response = await client.request<
    { value: TData[]; '@odata.count': number; '@search.facets': AzureSearchFacets },
    unknown
  >({
    path: `/indexes/${index}/docs`,
    method: 'GET',
    query: {
      'api-version': '2023-10-01-Preview',
      ...payload
    },
    secure: true,
    format: 'json',
    signal
  })

  const facets = Object.entries(response.data['@search.facets'] ?? {}).map(data => {
    data[1]?.sort((a, b) => collator.compare(a.value, b.value)) // NOSONAR

    return data
  })

  return {
    '@odata.count': response.data['@odata.count'],
    '@search.facets': Object.fromEntries(facets),
    value: response.data.value
  }
}
