import { useEffect, useRef } from 'react'
import type HxSelectionBox from '@katoennatie/hexagon/selection-box'
import { serializeForHexagon } from '@yms/hexagon/utils/serializeForHexagon'

export type SelectionBoxProps = {
  readonly name: string
  readonly label: string
  readonly value: string
  readonly checked?: boolean
  readonly disabled?: boolean
  readonly errorText?: string
  readonly variant?: 'radio' | 'checkbox'
  readonly onChange: (value: string, selected: boolean, event: Event) => void
}

export function SelectionBox({
  name,
  label,
  disabled,
  checked,
  onChange,
  value,
  errorText,
  variant,
  ...props
}: SelectionBoxProps) {
  const onChangeRef = useRef(onChange)
  const ref = useRef<HxSelectionBox>()

  useEffect(() => {
    const el = ref.current

    if (el) {
      const listener = (event: Event) => {
        const selected = (event.target as HTMLInputElement).checked
        onChangeRef.current(value, selected, event)
      }

      el.addEventListener('hx-change', listener)

      return () => {
        el.removeEventListener('hx-change', listener)
      }
    }
  }, [value])

  return (
    <hx-selection-box
      {...props}
      ref={ref}
      name={name}
      label={label}
      error-text={serializeForHexagon(errorText)}
      disabled={serializeForHexagon(disabled)}
      checked={serializeForHexagon(checked)}
      variant={serializeForHexagon(variant) ?? 'checkbox'}
    ></hx-selection-box>
  )
}
