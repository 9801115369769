import { get } from 'idb-keyval'
import { type HandlerType } from '@yms/api/client/v1/data-contracts'

export const currentYardZoneIds = {
  queryKey: ['current-session', { value: 'yardZoneIds' }],
  queryFn: async () => {
    const yardZoneIds = await get<string[]>('yardZoneIds')
    return yardZoneIds ?? null
  }
}

export const currentHandlerType = {
  queryKey: ['current-session', { value: 'handlerType' }],
  queryFn: async () => {
    return (await get<HandlerType>('handlerType')) ?? null
  }
}

export const currentHandlerId = {
  queryKey: ['current-session', { value: 'handlerId' }],
  queryFn: async () => {
    return (await get<string>('handlerId')) ?? null
  }
}
