/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ClearDefaultBlockPayload,
  CreateYardZonePayload,
  GetODataYardZonesParams,
  HttpValidationProblemDetails,
  ODataYardZoneDtoArrayODataCollectionResponse,
  ProblemDetails,
  SetDefaultBlockPayload,
  UpdateYardZonePayload,
  YardZoneDetailsDto
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class YardZones<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * No description
   *
   * @tags YardZones
   * @name UpdateYardZone
   * @request PUT:/api/v1/yard-zones/{id}
   * @secure
   */
  updateYardZone = (id: string, data: UpdateYardZonePayload, params: RequestParams = {}) =>
    this.http.request<YardZoneDetailsDto, HttpValidationProblemDetails>({
      path: `/api/v1/yard-zones/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags YardZones
   * @name SetDefaultBlock
   * @request PATCH:/api/v1/yard-zones/{id}/default-block/mark
   * @secure
   */
  setDefaultBlock = (id: string, data: SetDefaultBlockPayload, params: RequestParams = {}) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/yard-zones/${id}/default-block/mark`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    })
  /**
   * No description
   *
   * @tags YardZones
   * @name ClearDefaultBlock
   * @request PATCH:/api/v1/yard-zones/{id}/default-block/unmark
   * @secure
   */
  clearDefaultBlock = (id: string, data: ClearDefaultBlockPayload, params: RequestParams = {}) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/yard-zones/${id}/default-block/unmark`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    })
  /**
   * No description
   *
   * @tags YardZones
   * @name GetODataYardZones
   * @request GET:/api/v1/yard-zones
   * @secure
   */
  getODataYardZones = (query: GetODataYardZonesParams, params: RequestParams = {}) =>
    this.http.request<ODataYardZoneDtoArrayODataCollectionResponse, HttpValidationProblemDetails>({
      path: `/api/v1/yard-zones`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags YardZones
   * @name CreateYardZone
   * @request POST:/api/v1/yard-zones
   * @secure
   */
  createYardZone = (data: CreateYardZonePayload, params: RequestParams = {}) =>
    this.http.request<YardZoneDetailsDto, HttpValidationProblemDetails>({
      path: `/api/v1/yard-zones`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    })
  /**
   * No description
   *
   * @tags YardZones
   * @name DeactivateYardZone
   * @request PATCH:/api/v1/yard-zones/{id}/deactivate
   * @secure
   */
  deactivateYardZone = (id: string, params: RequestParams = {}) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/yard-zones/${id}/deactivate`,
      method: 'PATCH',
      secure: true,
      ...params
    })
  /**
   * No description
   *
   * @tags YardZones
   * @name ActivateYardZone
   * @request PATCH:/api/v1/yard-zones/{id}/activate
   * @secure
   */
  activateYardZone = (id: string, params: RequestParams = {}) =>
    this.http.request<
      void,
      HttpValidationProblemDetails | (ProblemDetails | HttpValidationProblemDetails)
    >({
      path: `/api/v1/yard-zones/${id}/activate`,
      method: 'PATCH',
      secure: true,
      ...params
    })
}
